// React & Props
import React from "react";
import PropTypes from "prop-types";

function Table({ caption, content, headers, className, keyPrefix }) {
  const contentArr =
    typeof content === "string" ? JSON.parse(content) : content;
  const useHeaders =
    typeof headers === "boolean"
      ? headers
      : headers && headers.toLowerCase() === "true";
  let classList = ["shared__table"];
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  const numCols =
    (contentArr && contentArr[0].row && contentArr[0].row.length) ||
    contentArr[0].length;
  const header = useHeaders ? (
    <thead>
      <tr key="row-header">
        {(contentArr[0].row || contentArr[0]).map((col, colIndex) => {
          const colName = `row-header-col-${colIndex}`;
          return (
            <th key={colName}>
              <p className="p4 bold">{col}</p>
            </th>
          );
        })}
      </tr>
    </thead>
  ) : null;
  const tableContent = useHeaders ? contentArr.slice(1) : contentArr;
  const body = (
    <tbody>
      {tableContent.map((row, rowIndex) => {
        const rowName = `${keyPrefix || ""}row-${rowIndex}`;
        const wrapperName = `${keyPrefix || ""}wrapper-${rowIndex}`;
        const rowObj = row.row || row;
        return (
          <React.Fragment key={wrapperName}>
            <tr
              key={rowName}
              className={row.subTable ? "shared__table-row-parent" : null}>
              {rowObj.map((col, colIndex) => {
                const colName = `${rowName}-col-${colIndex}`;
                if (colIndex < numCols) {
                  return (
                    <td key={colName}>
                      <p className="p4">{col}</p>
                    </td>
                  );
                }
                return null;
              })}
            </tr>
            {row.subTable ? (
              <tr key={`subtable-${rowName}`}>
                <td colSpan={`${numCols}`} className="shared__table-col-parent">
                  <Table
                    caption={row.subTableCaption}
                    content={row.subTable}
                    headers={useHeaders}
                    keyPrefix={`subtable-${rowName}-`}
                    className="shared__table-nested"
                  />
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      })}
    </tbody>
  );
  return (
    <div className="shared__table-wrapper">
      <table className={classList.join(" ")}>
        {caption ? <caption>{caption}</caption> : null}
        {header}
        {body}
      </table>
    </div>
  );
}

Table.propTypes = {
  caption: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
        PropTypes.shape({
          row: PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          ),
          subTable: PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.arrayOf(
                PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
              ),
              PropTypes.shape({
                row: PropTypes.arrayOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                ),
                subTable: PropTypes.arrayOf(
                  PropTypes.arrayOf(
                    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                  ),
                ),
              }),
            ]),
          ),
          subTableCaption: PropTypes.string,
        }),
      ]),
    ),
  ]).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  headers: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  keyPrefix: PropTypes.string,
};

Table.defaultProps = {
  caption: null,
  className: "",
  headers: false,
  keyPrefix: "",
};

Table.displayName = "Table";

export default Table;
