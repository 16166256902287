import React, { useState, Children, useMemo } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import loadable from "@loadable/component";
import { useCallbackRef } from "use-callback-ref";
import * as Breakpoints from "lattice/src/breakpoints.module.scss";
import { isMarkdown } from "../../utils";
import useScrollPosition from "../../hooks/useScrollPosition";
import useWindowSize from "../../hooks/useWindowSize";

const LatticeGrid = loadable(() =>
  import("lattice/lib/components/LatticeGrid"),
);
const Modal = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-organisms-modal' */ "./modal"
  ),
);
const Image = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-image' */ "../atoms/image"
  ),
);
// import CONSTANTS from "../../constants";

function MediaCarousel({
  children,
  className,
  settings,
  title,
  id,
  summary,
  reviewInfo,
  reviewModal,
  reviewStars,
  grid,
  fullWidth,
  backgroundImage,
  mobileBackgroundImage,
  backgroundColor,
  image,
  mobileImage,
  useSlider,
  useTitle,
  useWrapper,
  wrapperClassName,
  wrapperComponent,
  wrapperProps,
  wrapperChildren,
  forwardRef,
  props,
  lazyLoad,
  forceRender,
  renderProps,
}) {
  let mediaCarouselClasses = ["media__carousel"];
  if (summary) {
    mediaCarouselClasses.push("media__carousel--summary");
  }
  if (className) {
    mediaCarouselClasses = mediaCarouselClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
    // Special case for large press carousel color
    if (
      className.includes("large-press__carousel") &&
      children.length > 0 &&
      children[0].props.props.color === "light"
    ) {
      mediaCarouselClasses.push("large-press__carousel--light");
    }
  }
  if (backgroundColor) {
    mediaCarouselClasses.push(`media__carousel--${backgroundColor}`);
  }

  // Display carousel preview
  const mediaGroupProps = props;
  let mediaGroupPreview = null;
  if (mediaGroupProps.preview && mediaGroupProps.preview !== "boolean") {
    mediaCarouselClasses.push("mediagroup__preview");
    mediaGroupPreview = (
      <button
        className="contentful-preview"
        type="button"
        onClick={mediaGroupProps.preview}>
        preview
      </button>
    );
  }
  mediaCarouselClasses = mediaCarouselClasses.filter((mbc) => mbc !== "");

  const useRenderProps =
    renderProps && (renderProps.desktop >= 0 || renderProps.mobile >= 0);

  // Will defer rendering carousels out of window view until scrolling starts
  const isBrowser = typeof window !== `undefined`;
  const [renderCarousel, setRenderCarousel] = useState(forceRender);
  const [shouldRenderSomething, setShouldRenderSomething] = useState(
    (useRenderProps && renderProps.shouldRender) || false,
  );
  const windowDims = isBrowser ? document.body.getBoundingClientRect() : {};
  const { windowSize: size } = useRenderProps
    ? useWindowSize()
    : { windowSize: {} };

  const deviceType =
    size.width >= Breakpoints.gridTablet ? "desktop" : "mobile";
  const usePartial =
    useRenderProps && renderProps.usePartial && renderProps[deviceType] > 0;

  // const ref = useRef(forwardRef);
  const ref = useCallbackRef(forwardRef, (c) => {
    const carouselDims = (isBrowser && c && c.getBoundingClientRect()) || {};
    if (
      !renderCarousel &&
      !useRenderProps &&
      !(
        windowDims.top + windowDims.height < carouselDims.top ||
        carouselDims.top + carouselDims.height < windowDims.top
      )
    ) {
      setRenderCarousel(true);
    } else if (
      !renderCarousel &&
      !shouldRenderSomething &&
      !(
        windowDims.top + windowDims.height < carouselDims.top ||
        carouselDims.top + carouselDims.height < windowDims.top
      )
    ) {
      setShouldRenderSomething(true);
    }
  });
  useScrollPosition(
    () => {
      if (
        !renderCarousel &&
        (!useRenderProps || (useRenderProps && !renderProps.onChange))
      ) {
        setRenderCarousel(true);
      } else if (!shouldRenderSomething) {
        setShouldRenderSomething(true);
      }
    },
    [renderCarousel],
    ref,
  );

  // Setup mobile image breakpoints
  const imageSources =
    mobileImage && image
      ? {
          desktop: image,
          mobile: mobileImage,
        }
      : null;
  const backgroundImageSources =
    mobileBackgroundImage && backgroundImage
      ? {
          desktop: backgroundImage,
          mobile: mobileBackgroundImage,
        }
      : null;

  const focusSlider = (e) => {
    e.persist();
    const tagName = e && e.target.tagName;
    const validTagName = tagName !== "BUTTON" && tagName !== "SPAN";
    if (useRenderProps && usePartial && !renderCarousel) {
      setRenderCarousel(true);
    }
    if (
      validTagName &&
      ref &&
      ref.current &&
      settings &&
      settings.touchMove !== false
    ) {
      const track = ref.current.querySelector(".slick-track");
      const slide = track && track.querySelector(".slick-slide");
      if (slide) {
        slide.focus();
      }
    }
  };

  const actualChildren =
    useRenderProps && usePartial && !renderCarousel
      ? children.slice(0, renderProps[deviceType])
      : children;

  const carouselRenderChildren =
    useRenderProps && !renderProps.skipMemo && !usePartial
      ? Children.map(actualChildren, (c) =>
          useMemo(() => c, renderProps.memoValues || []),
        )
      : actualChildren;

  let carouselDeferChildren = Children.map(actualChildren, () => <div />);
  if (useRenderProps && shouldRenderSomething) {
    carouselDeferChildren = Children.map(actualChildren, (c, i) => {
      const canRenderChild =
        useRenderProps &&
        (renderProps[deviceType] > i || renderProps[deviceType] < 0);
      if (renderProps.skipMemo || usePartial) {
        return canRenderChild ? c : <div />;
      }
      return useMemo(
        () => (canRenderChild ? c : <div />),
        (renderProps.memoValues || []).concat([shouldRenderSomething]),
      );
    });
  } else if (
    useRenderProps &&
    !shouldRenderSomething &&
    !renderProps.skipMemo &&
    !usePartial
  ) {
    carouselDeferChildren = Children.map(actualChildren, () =>
      useMemo(
        () => <div />,
        (renderProps.memoValues || []).concat([shouldRenderSomething]),
      ),
    );
  }
  const carouselChild = renderCarousel
    ? carouselRenderChildren
    : carouselDeferChildren;

  const sliderComponent = useSlider ? (
    <Slider
      {...settings}
      beforeChange={
        useRenderProps && renderProps.onChange && !renderCarousel
          ? () => {
              if (typeof renderProps.onChange === "function") {
                renderProps.onChange();
              }
              setRenderCarousel(true);
            }
          : settings.beforeChange
      }>
      {carouselChild}
    </Slider>
  ) : (
    carouselChild
  );
  let wrapperCarouselClasses = ["carousel__wrapper"];
  if (wrapperClassName) {
    wrapperCarouselClasses = wrapperCarouselClasses.concat(
      Array.isArray(wrapperClassName)
        ? wrapperClassName
        : wrapperClassName.split(" "),
    );
  }
  const WrapperComponent = wrapperComponent;
  const wrapperEl = useWrapper ? (
    <WrapperComponent
      {...wrapperProps}
      className={wrapperCarouselClasses.join(" ")}>
      {wrapperChildren}
      {sliderComponent}
    </WrapperComponent>
  ) : (
    sliderComponent
  );

  // Process summary if there is one
  let carouselSummary = null;
  if (title) {
    carouselSummary = <h1>{title}</h1>;
    if (mediaGroupProps.route) {
      carouselSummary = (
        <h1>
          <a href={mediaGroupProps.route}>{title}</a>
        </h1>
      );
    }
  }
  if (
    summary &&
    isMarkdown(summary.summary, summary.childMarkdownRemark.html)
  ) {
    // if (summary && summary.summary.includes("\n")) {
    carouselSummary = (
      <div className="carousel__intro">
        {carouselSummary}
        <div
          className="carousel__summary"
          dangerouslySetInnerHTML={{
            __html: summary.childMarkdownRemark.html,
          }}
        />
      </div>
    );
  } else if (summary) {
    carouselSummary = (
      <div className="carousel__intro">
        {carouselSummary}
        <p className="carousel__summary">{summary.summary}</p>
      </div>
    );
  }

  const reviewModalEl =
    reviewModal && reviewModal.showReviewModal ? (
      <Modal
        handleClose={() => reviewModal.setShowReviewModal(false)}
        className="review__modal">
        {reviewModal.reviewModalContent}
      </Modal>
    ) : null;

  const isPreview = Boolean(mediaGroupProps.preview);
  return grid ? (
    <>
      {reviewModalEl}
      <LatticeGrid
        innerGrid
        fullWidth={fullWidth}
        forwardRef={ref}
        title={useTitle ? title : null}
        id={reviewInfo ? "reviewAnchor" : id}
        className={mediaCarouselClasses}
        onTouchStart={focusSlider}
        onMouseDown={focusSlider}>
        {mediaGroupPreview}
        {backgroundImage ? (
          <Image
            image={backgroundImageSources || backgroundImage}
            alt={backgroundImage.description}
            className="shared__bg-img"
            style={{ position: "absolute" }}
            loading={lazyLoad ? "lazy" : "eager"}
            legacy={isPreview}
            abState={mediaGroupProps.abState}
          />
        ) : null}
        {carouselSummary}
        {image ? (
          <Image
            image={imageSources || image}
            alt={image.description}
            className="carousel__main-image"
            loading={lazyLoad ? "lazy" : "eager"}
            legacy={isPreview}
            abState={mediaGroupProps.abState}
          />
        ) : null}
        {reviewInfo ? (
          <div className="review__carousel-details">
            <h1>{reviewInfo.score}</h1>
            <Image
              image={reviewStars}
              alt={reviewStars.description || "Review stars"}
              loading={lazyLoad ? "lazy" : "eager"}
              legacy={isPreview}
              abState={mediaGroupProps.abState}
            />
            <p>{reviewInfo.numReviews.toLocaleString()} Reviews</p>
          </div>
        ) : null}
        {wrapperEl}
      </LatticeGrid>
    </>
  ) : (
    <>
      {reviewModalEl}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={mediaCarouselClasses.join(" ")}
        id={id}
        ref={ref}
        title={useTitle ? title : null}
        onTouchStart={focusSlider}
        onMouseDown={focusSlider}>
        {mediaGroupPreview}
        {carouselSummary}
        {reviewInfo ? (
          <>
            <h1>{reviewInfo.score}</h1>
            <p>{reviewInfo.numReviews} Reviews</p>
          </>
        ) : null}
        {wrapperEl}
      </div>
    </>
  );
}

MediaCarousel.designSystemProps = {
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    default: "",
    required: false,
  },
  id: {
    type: "string",
    description: "Unique identifier for the carousel.",
    propType: PropTypes.string,
    default: null,
    required: false,
  },
  title: {
    type: "string",
    description: "Carousel headline.",
    propType: PropTypes.string,
    default: "",
    required: false,
  },
  summary: {
    type: "string",
    description: "Summary or text description of carousel.",
    propType: PropTypes.shape({
      summary: PropTypes.string,
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    subProps: {
      summary: {
        type: "string",
        description: "DOM node of Ref object",
        required: false,
      },
      childMarkdownRemark: {
        type: "object",
        description: "Markdown HTML object",
        subProps: {
          html: {
            type: "string",
            description: "HTML content.",
            required: false,
          },
        },
        required: false,
      },
    },
    default: null,
    required: false,
  },
  reviewInfo: {
    type: "object",
    description: "Info for review carousel.",
    propType: PropTypes.shape({
      score: PropTypes.number,
      numReviews: PropTypes.number,
    }),
    subProps: {
      score: {
        type: "number",
        description: "Numeric value of product review score.",
        required: false,
      },
      numReviews: {
        type: "number",
        description: "Total number of reviews.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
  reviewModal: {
    type: "object",
    description: "Setting to manage for review modals.",
    propType: PropTypes.shape({
      showReviewModal: PropTypes.bool,
      reviewModalContent: PropTypes.node,
      setShowReviewModal: PropTypes.func,
    }),
    subProps: {
      showReviewModal: {
        type: "boolean",
        description: "Review modal is displayed if true.",
        required: false,
      },
      reviewModalContent: {
        type: "node",
        description: "Review modal content.",
        required: false,
      },
      setShowReviewModal: {
        type: "function",
        description: "Handler for showing/hiding reivew modal.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
  reviewStars: {
    type: "object",
    description: "Review stars image.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
    },
    default: null,
    required: false,
  },
  children: {
    type: "node",
    description: "Carousel content.",
    propType: PropTypes.node,
    default: null,
    required: false,
  },
  grid: {
    type: "boolean",
    description: "Utilizes Lattice grid for media block if true.",
    propType: PropTypes.bool,
    default: true,
    required: false,
  },
  fullWidth: {
    type: "boolean",
    description: "Carousel spans full screen width if true.",
    propType: PropTypes.bool,
    default: true,
    required: false,
  },
  backgroundColor: {
    type: "string",
    description: "Background color.",
    propType: PropTypes.string,
    default: null,
    required: false,
  },
  backgroundImage: {
    type: "object",
    description: "Background image of carousel.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
    },
    default: null,
    required: false,
  },
  mobileBackgroundImage: {
    type: "object",
    description: "Background mobile image of carousel.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
    },
    default: null,
    required: false,
  },
  image: {
    type: "object",
    description: "Main image of carousel.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
    },
    default: null,
    required: false,
  },
  mobileImage: {
    type: "object",
    description: "Main mobile image of carousel.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
    },
    default: null,
    required: false,
  },
  settings: {
    type: "object",
    description: "Carousel slider settings object.",
    propType: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array,
        PropTypes.node,
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.bool,
            PropTypes.number,
          ]),
        ),
      ]),
    ),
    subProps: {
      dots: {
        type: "boolean",
        description: "Displays slider navigation if true.",
        required: false,
      },
      infinite: {
        type: "boolean",
        description: "Infinite navigation if true.",
        required: false,
      },
      speed: {
        type: "number",
        description: "Time in milliseconds for slider animation to complete.",
        required: false,
      },
      slidesToScroll: {
        type: "boolean",
        description: "Displays slider navigation if true.",
        required: false,
      },
      variableWidth: {
        type: "boolean",
        description: "Allows for fluid slide width if true.",
        required: false,
      },
      lazyLoad: {
        type: "string",
        description: `Lazy load strategy ("progressive" | "ondemand") `,
        required: false,
      },
      arrows: {
        type: "boolean",
        description: "Displays next and previous arrows if true.",
        required: false,
      },
      centerMode: {
        type: "boolean",
        description: "Displays current slide at center if true.",
        required: false,
      },
      initialSlide: {
        type: "number",
        description: "Index of first slide to display.",
        required: false,
      },
      responsive: {
        type: "array",
        description: "List of device specific settings.",
        required: false,
        subProps: {
          breakpoint: {
            type: "number",
            description:
              "Max width in pixels where all settings will be applied.",
            required: false,
          },
          settings: {
            type: "object",
            description: "Settings object at specified breakpoint.",
            required: false,
          },
        },
      },
    },
    default: {},
    required: false,
  },
  useSlider: {
    type: "boolean",
    description: "Uses slider functionality for group if true.",
    propType: PropTypes.bool,
    default: true,
    required: false,
  },
  useTitle: {
    type: "boolean",
    description: "Display title attribute with title property if true.",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  useWrapper: {
    type: "boolean",
    description: "Adds wrapper element around carousel if true.",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  wrapperClassName: {
    type: ["string", "array"],
    description: "List of classes for this wrapper element.",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    default: "",
    required: false,
  },
  wrapperComponent: {
    type: "element",
    description: "Carousel wrapper component.",
    propType: PropTypes.elementType,
    default: "div",
    required: false,
  },
  wrapperProps: {
    type: "object",
    description: "Additional carousel wrapper config.",
    propType: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({
          name: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.string),
        }),
        PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              shopifyColorOption: PropTypes.shape({
                color: PropTypes.string,
              }),
              hexValue: PropTypes.string,
              hexValue2: PropTypes.string,
            }),
          }),
        ),
      ]),
    ),
    default: {},
    required: false,
  },
  wrapperChildren: {
    type: "node",
    description: "Carosuel wrapper content.",
    propType: PropTypes.node,
    default: null,
    required: false,
  },
  forwardRef: {
    type: ["function", "object"],
    description: "Ref to component or DOM element.",
    subProps: {
      current: {
        type: ["any"],
        description: "DOM node of Ref object",
        required: false,
        default: null,
      },
    },
    required: false,
    default: null,
    propType: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
  },
  props: {
    type: "object",
    description: "Additional carousel config.",
    propType: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.bool,
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.object,
          ]),
        ),
        PropTypes.arrayOf(PropTypes.any),
      ]),
    ),
    subProps: {
      route: {
        type: "string",
        description: "URL route link.",
        required: false,
      },
    },
    default: {},
    required: false,
  },
  lazyLoad: {
    type: "boolean",
    description: "Lazy load media carousel image if true.",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  forceRender: {
    type: "boolean",
    description: "Forces a carousel render on page load",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  renderProps: {
    type: "object",
    description: "Strategy for rendering carousel on page load",
    propType: PropTypes.shape({
      desktop: PropTypes.number,
      mobile: PropTypes.number,
    }),
    default: null,
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(MediaCarousel.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
MediaCarousel.propTypes = { ...propTypes };
MediaCarousel.defaultProps = { ...defaultProps };

MediaCarousel.displayName = "MediaCarousel";

export default MediaCarousel;
