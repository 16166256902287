import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import IframeResizer from "iframe-resizer-react";
// import CodeBlock from "../atoms/code-block";
import * as designStyles from "../../styles/design-styles.module.scss";
import { getCarouselSettings, getMediaBlockProps } from "../../utils";

const CodeBlock = loadable(() => import("../atoms/code-block"));

function DesignStory({
  title,
  type,
  compact,
  children,
  props,
  subProps,
  component,
  hideDisplay,
  subComponent,
  className,
  wrapperComponent,
  wrapperProps,
}) {
  const storyProps = props;
  const addBorder =
    title.toLowerCase().includes("white") ||
    title.toLowerCase().includes("grey");
  if (type === "color") {
    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? (
          <p className="labeltext">
            {title}: {designStyles[title]}
          </p>
        ) : null}
        <div
          className={`design__color-block shared__bg-${title} ${
            addBorder ? "design__color-block--light" : ""
          }`}
        />
        <CodeBlock language="html">{`<div className="shared__bg-${title}" />`}</CodeBlock>
      </div>
    );
  }
  if (type === "type") {
    const Tag = title;
    const typeProps = Object.entries(props).map(
      ([attr, value]) => `${attr}="${value}"`,
    );
    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        <Tag className={storyProps.className}>{children}</Tag>
        <CodeBlock language="html">{`<${Tag}${
          typeProps.length ? " " : ""
        }${typeProps.join(" ")}>${children}</${Tag}>`}</CodeBlock>
      </div>
    );
  }
  if (type === "space") {
    const Tag = title;
    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}
        style={{ position: "relative" }}>
        <div className="design__spacing-wrapper">
          <div className={`design__spacing ${storyProps.className}`}>
            <Tag className={`type-sp-${storyProps.className}`}>{children}</Tag>
          </div>
        </div>
        <span className="labeltext type-sp-micro">jsx</span>
        <CodeBlock language="jsx">{`<${Tag} className={\`type-sp-${storyProps.className}\`}>${children}</${Tag}>`}</CodeBlock>
        <span className="labeltext type-sp-micro">scss</span>
        <CodeBlock language="scss">{`@include typography-styles("${Tag}", ${
          designStyles[storyProps.className]
        });`}</CodeBlock>
      </div>
    );
  }
  if (type === "space-line") {
    const Tag = title;
    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}
        style={{ position: "relative" }}>
        <h6 className="type-sp-micro">{storyProps.name}</h6>
        <div className="design__spacing-line-wrapper">
          <Tag
            className={`design__spacing-line ${storyProps.pos} ${storyProps.className}`}
          />
        </div>

        <CodeBlock language="scss">{`@include ltc-border-padding(
  $border-top-pixels: ${storyProps.pos === "top" ? 1 : 0},
  $border-bottom-pixels: ${storyProps.pos === "bottom" ? 1 : 0},
  $padding-top: ${designStyles[storyProps.className] * 2},
  $border-top-color: $black
);`}</CodeBlock>
      </div>
    );
  }
  if (type === "space-component") {
    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        <div className="design__spacing-component">
          <div className="spacing-pixel-wrapper">
            <div className="spacing-pixel">
              <p className="labeltext">
                {designStyles[storyProps.className] * 16}px
              </p>
            </div>
          </div>
          <div className={`spacing-horiz-line ${storyProps.className}`}>
            <div className="spacing-vert-line" />
          </div>
          <div className={`spacing-display ${storyProps.className}`} />
          <div className="spacing-desc">
            <h6 className="type-sp-none">{title}</h6>
            <p className="p3 type-sp-none">
              {designStyles[storyProps.className]}rem
            </p>
          </div>
        </div>
        <CodeBlock language="scss">{`@include ltc-spacing($property: "margin-top", $size: "${storyProps.className}");
@include ltc-spacing($property: "margin-bottom", $size: "${storyProps.className}");
@include ltc-spacing($property: "padding-top", $size: "${storyProps.className}");
@include ltc-spacing($property: "padding-bottom", $size: "${storyProps.className}");`}</CodeBlock>
      </div>
    );
  }
  if (type === "nav") {
    const Component = component;
    const propsList = Object.entries(storyProps.section || storyProps)
      .filter((arr) => arr[0] !== "slug")
      .map(([name, value]) => {
        const quotes = typeof value === "string" ? '"' : "";
        return `${name}={${quotes}${
          typeof value === "object" ? JSON.stringify(value, null, 2) : value
        }${quotes}}`;
      });

    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? <h6>{title}</h6> : null}
        <IframeResizer
          src={`/demo/${storyProps.slug}/`}
          heightCalculationMethod="documentElementScroll"
          className="design__iframe design__iframe--nav"
          title="Demo"
        />
        <CodeBlock language="jsx">{`<${Component.displayName} ${propsList.join(
          "\n",
        )} />`}</CodeBlock>
      </div>
    );
  }
  if (type === "component") {
    let classList = [];
    classList = classList.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
    const Component = component;
    let componentList = null;
    if (subComponent) {
      componentList = Array.isArray(subComponent)
        ? subComponent
        : [subComponent];
    }

    const propsList = Object.entries(props).map(([name, value]) => {
      const quotes = typeof value === "string" ? '"' : "";
      // If Element Type, use display name
      const propValue = name === "icon" ? value.displayName : value;
      return `${name}={${quotes}${
        typeof propValue === "object"
          ? JSON.stringify(propValue, null, 2)
          : propValue
      }${quotes}}`;
    });
    if (subProps) {
      return (
        <div
          className={`design__story ${
            compact ? "design__story--compact" : ""
          }`}>
          {title ? <p className="labeltext">{title}</p> : null}
          {!hideDisplay ? (
            <Component {...props} className={classList.join(" ")}>
              {componentList.map((SubComponent, index) => (
                <SubComponent
                  {...subProps[index]}
                  key={`${SubComponent.displayName}-${subProps[index].id}`}
                />
              ))}
            </Component>
          ) : null}
          <CodeBlock language="jsx">{`<${
            Component.displayName
          } ${propsList.join(" ")}>\n${componentList
            .map((SubComponent, index) => {
              const subPropsList = Object.entries(subProps[index]).map(
                ([name, value]) => {
                  const quotes = typeof value === "string" ? '"' : "";
                  return `${name}={${quotes}${
                    typeof value === "object"
                      ? JSON.stringify(value, null, 2)
                      : value
                  }${quotes}}`;
                },
              );
              return `<${SubComponent.displayName} ${subPropsList.join(
                " ",
              )} />\n`;
            })
            .join("")}</${Component.displayName}>`}</CodeBlock>
        </div>
      );
    }
    return children ? (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? <p className="labeltext">{title}</p> : null}
        {!hideDisplay ? (
          <Component {...props} className={classList.join(" ")}>
            {children}
          </Component>
        ) : null}
        <CodeBlock language="jsx">{`<${Component.displayName} ${propsList.join(
          " ",
        )}>${children}</${Component.displayName}>`}</CodeBlock>
      </div>
    ) : (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? <p className="labeltext">{title}</p> : null}
        {!hideDisplay ? (
          <Component {...props} className={classList.join(" ")} />
        ) : null}
        <CodeBlock language="jsx">{`<${Component.displayName} ${propsList.join(
          " ",
        )} />`}</CodeBlock>
      </div>
    );
  }
  if (type === "media") {
    const Component = component;
    const WrapperComponent = wrapperComponent;

    // Build wrapper props if any
    const wrapperPropsList = Object.entries(wrapperProps).map(
      ([name, value]) => {
        const quotes = typeof value === "string" ? '"' : "";
        return `${name}={${quotes}${
          typeof value === "object" ? JSON.stringify(value, null, 2) : value
        }${quotes}}`;
      },
    );

    const propsList = Object.entries(storyProps.section || storyProps)
      .filter((arr) => arr[0] !== "slug")
      .map(([name, value]) => {
        const quotes = typeof value === "string" ? '"' : "";
        return `${name}={${quotes}${
          typeof value === "object" ? JSON.stringify(value, null, 2) : value
        }${quotes}}`;
      });

    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? <h6>{title}</h6> : null}
        <IframeResizer
          src={`/demo/${storyProps.slug}/`}
          heightCalculationMethod="documentElementScroll"
          className={`design__iframe design__iframe--${storyProps.type}`}
          title="Demo"
        />
        <CodeBlock language="jsx">{`${
          wrapperComponent
            ? `<${WrapperComponent.displayName} ${wrapperPropsList.join(
                " ",
              )}>\n`
            : ""
        }<${Component.displayName} type="${storyProps.type}" ${propsList.join(
          "\n",
        )} />${
          wrapperComponent ? `\n</${WrapperComponent.displayName}>` : ""
        }`}</CodeBlock>
      </div>
    );
  }
  if (type === "carousel") {
    const Component = component;
    const SubComponent = subComponent;
    const WrapperComponent = wrapperComponent;

    // Build carousel props
    let storyCarouselType = storyProps.mediaBlocks
      ? storyProps.mediaBlocks[0].type
      : "review";
    if (storyProps.mediaBlocks && storyProps.mediaBlocks[0].products) {
      storyCarouselType = "collection";
    }
    const carouselSettings = getCarouselSettings(storyCarouselType);
    const carouselClasses = Array.isArray(storyProps.className)
      ? storyProps.className.join(" ")
      : storyProps.className;
    const carouselPropsList = Object.entries({
      ...carouselSettings,
      className: carouselClasses || `${storyCarouselType}__carousel`,
      ...(storyProps.headline ? { title: storyProps.headline } : undefined),
      summary: storyProps.summary,
      backgroundColor: storyProps.backgroundColor,
      backgroundImage: storyProps.backgroundImage,
      image: storyProps.image,
      useWrapper: storyCarouselType === "lifestyle",
      wrapperComponent: storyCarouselType === "lifestyle" ? "div" : null,
      useSlider: storyProps.useSlider !== false,

      ...(storyCarouselType === "review" && storyProps
        ? { reviewInfo: storyProps.details }
        : undefined),
      ...(storyCarouselType === "review" && storyProps
        ? {
            reviewModal: {},
          }
        : undefined),
    }).map(([name, value]) => {
      const quotes = typeof value === "string" ? '"' : "";
      // Remove nextArrow, prevArrow attributes if present
      const cleanValue = typeof value === "object" ? { ...value } : value;
      if (
        typeof cleanValue === "object" &&
        (cleanValue.nextArrow || cleanValue.prevArrow)
      ) {
        delete cleanValue.nextArrow;
        delete cleanValue.prevArrow;
      }
      return `${name}={${quotes}${
        typeof cleanValue === "object"
          ? JSON.stringify(cleanValue, null, 2)
          : cleanValue
      }${quotes}}`;
    });

    // Build wrapper props if any
    const wrapperPropsList = Object.entries(wrapperProps).map(
      ([name, value]) => {
        const quotes = typeof value === "string" ? '"' : "";
        return `${name}={${quotes}${
          typeof value === "object" ? JSON.stringify(value, null, 2) : value
        }${quotes}}`;
      },
    );

    // Build inner media blocks props
    const propsList = [];
    if (storyProps.mediaBlocks && storyProps.mediaBlocks[0].products) {
      storyProps.mediaBlocks[0].products.map((block) => {
        propsList.push(
          Object.entries(block).map(([name, value]) => {
            const quotes = typeof value === "string" ? '"' : "";
            return `${name}={${quotes}${
              typeof value === "object" ? JSON.stringify(value, null, 2) : value
            }${quotes}}`;
          }),
        );
        return true;
      });
    } else if (storyProps.mediaBlocks) {
      storyProps.mediaBlocks.map((block) => {
        propsList.push(
          Object.entries(block).map(([name, value]) => {
            const quotes = typeof value === "string" ? '"' : "";
            return `${name}={${quotes}${
              typeof value === "object" ? JSON.stringify(value, null, 2) : value
            }${quotes}}`;
          }),
        );
        return true;
      });
    } else {
      storyProps.list.map((review) => {
        const reviewBlock = {
          type: "review",
          className: "product__review",
          ...getMediaBlockProps({
            type: "review",
            review,
          }),
          key: review.id,
        };
        propsList.push(
          Object.entries(reviewBlock).map(([name, value]) => {
            const quotes = typeof value === "string" ? '"' : "";
            return `${name}={${quotes}${
              typeof value === "object" ? JSON.stringify(value, null, 2) : value
            }${quotes}}`;
          }),
        );
        return true;
      });
    }
    // console.log("propslist", propsList);
    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? <h6>{title}</h6> : null}
        <IframeResizer
          src={`/demo/${storyProps.slug || storyCarouselType}/`}
          heightCalculationMethod="documentElementScroll"
          className={`design__iframe design__iframe--${storyCarouselType}`}
          title="Demo"
        />
        <CodeBlock language="jsx">{`${
          wrapperComponent
            ? `<${WrapperComponent.displayName} ${wrapperPropsList.join(
                " ",
              )}>\n`
            : ""
        }<${Component.displayName} ${carouselPropsList.join(" ")}>\n${propsList
          .map(
            (pList) =>
              `<${
                SubComponent.displayName || subProps.displayName
              } ${pList.join("\n")} />\n`,
          )
          .join("")}</${Component.displayName}>${
          wrapperComponent ? `\n</${WrapperComponent.displayName}>` : ""
        }`}</CodeBlock>
      </div>
    );
  }
  if (type === "bundle") {
    // const Component = component;
    // const WrapperComponent = wrapperComponent;

    // // Build wrapper props if any
    // const wrapperPropsList = Object.entries(wrapperProps).map(
    //   ([name, value]) => {
    //     const quotes = typeof value === "string" ? '"' : "";
    //     return `${name}={${quotes}${
    //       typeof value === "object" ? JSON.stringify(value, null, 2) : value
    //     }${quotes}}`;
    //   },
    // );

    // const propsList = Object.entries(storyProps.section || storyProps)
    //   .filter((arr) => arr[0] !== "slug")
    //   .map(([name, value]) => {
    //     const quotes = typeof value === "string" ? '"' : "";
    //     return `${name}={${quotes}${
    //       typeof value === "object" ? JSON.stringify(value, null, 2) : value
    //     }${quotes}}`;
    //   });

    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? <h6>{title}</h6> : null}
        <IframeResizer
          src={`/demo/${storyProps.slug}/`}
          heightCalculationMethod="documentElementScroll"
          className={`design__iframe design__iframe--${storyProps.type}`}
          title="Demo"
        />
        {/* <CodeBlock language="jsx">{`${
          wrapperComponent
            ? `<${WrapperComponent.displayName} ${wrapperPropsList.join(
                " ",
              )}>\n`
            : ""
        }<${Component.displayName} type="${storyProps.type}" ${propsList.join(
          "\n",
        )} />${
          wrapperComponent ? `\n</${WrapperComponent.displayName}>` : ""
        }`}</CodeBlock> */}
      </div>
    );
  }
  if (type === "demo-grid") {
    const Component = component;
    const gridProps = props;
    let imgProp = null;

    const propsList = Object.entries(props).map(([name, value]) => {
      const quotes = typeof value === "string" ? '"' : "";
      if (name === "gridDemo") {
        return null;
      }
      if (name === "image") {
        imgProp = `${name}={${quotes}${
          typeof value === "object" ? JSON.stringify(value, null, 2) : value
        }${quotes}}`;
        return null;
      }
      return `${name}={${quotes}${
        typeof value === "object" ? JSON.stringify(value, null, 2) : value
      }${quotes}}`;
    });

    return (
      <div
        className={`design__story ${compact ? "design__story--compact" : ""}`}>
        {title ? <h6>{title}</h6> : null}
        <IframeResizer
          heightCalculationMethod="documentElementScroll"
          src={`/demo/${subProps.type}/`}
          className="design__iframe"
          title="Demo"
        />
        <CodeBlock language="jsx">{`<${Component.displayName} ${propsList
          .join(" ")
          .trim()}>\n${
          gridProps.image
            ? `<Image ${imgProp} className="shared__bg-img grid-demo__image" loading="eager" style={{ position: "absolute" }} />\n`
            : ""
        }${children
          .map((c) => `<${c.type}>${c.props.children}</${c.type}>\n`)
          .join("")}</${Component.displayName}>`}</CodeBlock>
      </div>
    );
  }
  return null;
}

DesignStory.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  compact: PropTypes.bool,
  props: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.func,
      PropTypes.bool,
      PropTypes.shape({
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            shopifyColorOption: PropTypes.shape({
              color: PropTypes.string,
            }),
            hexValue: PropTypes.string,
            hexValue2: PropTypes.string,
          }),
        }),
      ),
    ]),
  ),
  subProps: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.func,
          PropTypes.bool,
          PropTypes.shape({
            name: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.string),
          }),
          PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                shopifyColorOption: PropTypes.shape({
                  color: PropTypes.string,
                }),
                hexValue: PropTypes.string,
                hexValue2: PropTypes.string,
              }),
            }),
          ),
        ]),
      ),
    ),
    PropTypes.objectOf(PropTypes.any),
  ]),
  children: PropTypes.node,
  hideDisplay: PropTypes.bool,
  component: PropTypes.elementType,
  subComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.arrayOf(PropTypes.elementType),
  ]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  wrapperComponent: PropTypes.elementType,
  wrapperProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ),
};

DesignStory.defaultProps = {
  title: "",
  type: "",
  compact: false,
  props: {},
  subProps: null,
  children: null,
  hideDisplay: false,
  component: null,
  subComponent: null,
  className: "",
  wrapperComponent: null,
  wrapperProps: {},
};
export default DesignStory;
