import React from "react";
import PropTypes from "prop-types";
// import { OutboundLink } from "gatsby-plugin-google-analytics";

function Button({
  route,
  color,
  compact,
  inactive,
  outlined,
  onClick,
  className,
  forwardRef,
  children,
  submit,
  label,
  openNewWindow,
}) {
  let classList = ["shared__button", `shared__button--${color}`];
  const inactiveClick = (e) => {
    e.preventDefault();
  };
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  if (compact) {
    classList.push("shared__button--compact");
  }
  if (inactive) {
    classList.push("shared__button--inactive");
  }
  if (outlined) {
    classList.push("shared__button--outlined");
  }

  if (submit) {
    classList.push("shared__button--submit");
    return (
      <button
        type="submit"
        className={classList.join(" ")}
        onClick={inactive ? inactiveClick : onClick}
        onKeyPress={inactive ? inactiveClick : onClick}
        ref={forwardRef}>
        <span>
          {children}
          {label ? (
            <aside className="button-aside" role="none">
              {" "}
              {label}
            </aside>
          ) : null}
        </span>
      </button>
    );
  }
  // const LinkTag = route.startsWith("http") ? OutboundLink : "a";
  const LinkTag = "a";
  const targetProps = openNewWindow
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {};
  return (
    <LinkTag
      href={route}
      {...targetProps}
      title={`${children}${label ? ` ${label}` : ""}`}
      className={classList.join(" ")}
      onClick={inactive ? inactiveClick : onClick}
      onKeyPress={inactive ? inactiveClick : onClick}
      ref={forwardRef}
      role="button"
      tabIndex={0}>
      <span>
        {children}
        {label ? (
          <aside className="button-aside" role="none">
            {label}{" "}
          </aside>
        ) : null}
      </span>
    </LinkTag>
  );
}

Button.designSystemProps = {
  route: {
    type: "string",
    description: "The URL the button directs to when clicked.",
    default: "#",
    propType: PropTypes.string,
    required: false,
  },
  color: {
    type: "string",
    description: "Button color.",
    default: "lightEucalyptus",
    propType: PropTypes.string,
    required: false,
  },
  compact: {
    type: "boolean",
    description: "Smaller sized button if true.",
    default: false,
    propType: PropTypes.bool,
    required: false,
  },
  children: {
    type: "node",
    description: "Button text.",
    default: "Get Started",
    propType: PropTypes.node,
    required: false,
  },
  inactive: {
    type: "boolean",
    description: "Button is not active if true.",
    default: false,
    propType: PropTypes.bool,
    required: false,
  },
  outlined: {
    type: "boolean",
    description: "Button uses outline style if true.",
    required: false,
    default: false,
    propType: PropTypes.bool,
  },
  onClick: {
    type: "function",
    description: "Function that executes when clicked.",
    required: false,
    default: () => {},
    propType: PropTypes.func,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  forwardRef: {
    type: ["function", "object"],
    description: "Ref to component or DOM element.",
    subProps: {
      current: {
        type: ["any"],
        description: "DOM node of Ref object",
        required: false,
        default: null,
      },
    },
    required: false,
    default: null,
    propType: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
  },
  submit: {
    type: "boolean",
    description: "Uses form submit if true.",
    required: false,
    default: false,
    propType: PropTypes.bool,
  },
  label: {
    type: "string",
    description:
      "Label to add for accessibility (not visible in visual browsers)",
    required: false,
    default: null,
    propType: PropTypes.string,
  },
  openNewWindow: {
    type: "boolean",
    description: "Opens link in new window if true.",
    required: false,
    default: false,
    propType: PropTypes.bool,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Button.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Button.propTypes = { ...propTypes };
Button.defaultProps = { ...defaultProps };

Button.displayName = "Button";

export default Button;
