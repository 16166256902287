// React & Props
import React from "react";
import PropTypes from "prop-types";

// import LatticeGrid from "lattice/lib/components/LatticeGrid";
// import SEO from "../components/functional/seo";

function Option({ children, name, activeOption, onClick, outOfStock, hidden }) {
  return (
    <button
      className={`option__button option__button--${name} ${
        outOfStock ? "option__button--oos" : ""
      } ${
        activeOption === children && !hidden ? "option__button--active" : ""
      } ${hidden ? "option__button--hidden" : ""}`}
      onClick={onClick}
      onKeyPress={onClick}
      type="button">
      <span>{children}</span>
    </button>
  );
}
Option.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  outOfStock: PropTypes.bool,
  onClick: PropTypes.func,
  activeOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hidden: PropTypes.bool,
};

Option.defaultProps = {
  outOfStock: false,
  onClick: () => {},
  activeOption: null,
  hidden: false,
};

Option.displayName = "Option";

export default Option;
