// React & Props
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Internal Components
import Option from "../atoms/option";

// import LatticeGrid from "lattice/lib/components/LatticeGrid";
// import SEO from "../components/functional/seo";

// Utils
import { slugify, isOOS } from "../../utils";

function OptionGroup({
  options,
  activeIndex,
  variants,
  activeVariant,
  setActiveVariant,
  setActiveParams,
  oosQty,
  noInventory,
  summary,
  hideOptions,
}) {
  const [activeOption, setActiveOption] = useState(options.values[activeIndex]);

  useEffect(() => {
    if (activeIndex || activeIndex === 0) {
      setActiveOption(options.values[activeIndex]);
    }
  }, [activeIndex]);

  return (
    <div
      className={`product-option__group option__group option__group--${slugify(
        options.name.toLowerCase(),
      )}`}>
      <p className="option__group-label">
        {options.name.toUpperCase()} {summary ? <mark>{summary}</mark> : null}
      </p>
      {options.values.map((option) => (
        <Option
          name={options.name.toLowerCase()}
          onClick={(e) => {
            e.preventDefault();
            if (activeVariant.title) {
              // Replace active variant title with new option
              const newVariantTitle = { ...activeVariant }.title.replace(
                activeOption,
                option,
              );
              const newVariant = variants.find(
                (v) => v.title === newVariantTitle,
              );
              if (newVariant) {
                setActiveVariant({ ...newVariant });
              }
            }
            const optionParam = setActiveParams[options.name.toLowerCase()];
            if (optionParam) {
              optionParam(option);
            }
            setActiveOption(option);
          }}
          activeOption={activeOption}
          hidden={
            hideOptions
              ? hideOptions.find(
                  (ho) => ho.shopifyOption && ho.shopifyOption.name === option,
                ) !== undefined
              : false
          }
          outOfStock={isOOS({
            option,
            activeVariant,
            options,
            variants,
            oosQty,
            noInventory,
          })}
          key={option}>
          {option}
        </Option>
      ))}
    </div>
  );
}

OptionGroup.designSystemProps = {
  options: {
    type: "object",
    description: "Options info.",
    propType: PropTypes.shape({
      name: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    }).isRequired,
    required: true,
    subProps: {
      name: {
        type: "string",
        description: "Option name.",
        required: true,
      },
      values: {
        type: "array",
        description: "Option values.",
        required: true,
      },
    },
  },
  activeIndex: {
    type: "number",
    description: "0 based index of active option.",
    propType: PropTypes.number,
    default: 0,
    required: false,
  },
  variants: {
    type: "array",
    description: "List of variant data for options.",
    propType: PropTypes.arrayOf(PropTypes.any),
    default: [],
    required: false,
  },
  activeVariant: {
    type: "object",
    description: "Variant info for active option.",
    propType: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    default: {},
    required: false,
    subProps: {
      id: {
        type: "string",
        description: "Variant ID.",
        required: false,
      },
      title: {
        type: "string",
        description: "Variant title.",
        required: false,
      },
    },
  },
  setActiveVariant: {
    type: "function",
    description: "Event handler when option is selected.",
    propType: PropTypes.func,
    default: () => {},
    required: false,
  },
  setActiveParams: {
    type: "object",
    description: "Event handlers when option URL parameter is updated.",
    propType: PropTypes.shape({
      quantity: PropTypes.func,
      size: PropTypes.func,
      firmness: PropTypes.func,
      color: PropTypes.func,
      value: PropTypes.func,
    }),
    default: {
      quantity: null,
      size: null,
      firmness: null,
      color: null,
      value: null,
    },
    required: false,
    subProps: {
      quantity: {
        type: "function",
        description: "Quantity event handler.",
        required: false,
        default: null,
      },
      size: {
        type: "function",
        description: "Size event handler.",
        required: false,
        default: null,
      },
      firmness: {
        type: "function",
        description: "Firmness event handler.",
        required: false,
        default: null,
      },
      color: {
        type: "function",
        description: "Color event handler.",
        required: false,
        default: null,
      },
      value: {
        type: "function",
        description: "Value event handler.",
        required: false,
        default: null,
      },
    },
  },
  oosQty: {
    type: "number",
    description: "Minimum quantity to determine if option is out of stock.",
    propType: PropTypes.number,
    default: null,
    required: false,
  },
  noInventory: {
    type: "boolean",
    description:
      "True if option is from a product that carries no inventory (i.e. gift card)",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  summary: {
    type: "string",
    description: "Option summary display text.",
    propType: PropTypes.string,
    default: null,
    required: false,
  },
  hideOptions: {
    type: "array",
    description: "List of options that will be hidden.",
    propType: PropTypes.arrayOf(
      PropTypes.shape({
        shopifyOption: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    default: [],
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(OptionGroup.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
OptionGroup.propTypes = { ...propTypes };
OptionGroup.defaultProps = { ...defaultProps };

OptionGroup.displayName = "OptionGroup";

export default OptionGroup;
