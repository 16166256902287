import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { getSrc } from "gatsby-plugin-image";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import format from "date-fns/format";
// import Axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";

import loadable from "@loadable/component";

import {
  slugify,
  isMarkdown,
  isExternalLink,
  listSearch,
  findLongestWord,
  isStaticImage,
  getCarouselSettings,
  stripHtml,
} from "../../utils";

const LatticeGrid = loadable(() =>
  import("lattice/lib/components/LatticeGrid"),
);
const MediaCarousel = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-organisms-media-carousel' */ "./media-carousel"
  ),
);
const Accordion = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-molecules-accordion' */ "../molecules/accordion"
  ),
);
// import Badge from "../atoms/badge";
const Badge = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-badge' */ "../atoms/badge"
  ),
);
const Button = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-button' */ "../atoms/button"
  ),
);
const Video = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-video' */ "../atoms/video"
  ),
);
const Image = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-atoms-image' */ "../atoms/image"
  ),
);
const SwatchGroup = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-molecules-swatch-group' */ "../molecules/swatch-group"
  ),
);
const EmailSubscribe = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-molecules-email-subscribe' */ "../molecules/email-subscribe"
  ),
);
const Wrapper = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-functional-wrapper' */ "../functional/wrapper"
  ),
);
// const Pencilbar = loadable(() =>
//   import(
//     /* webpackChunkName: 'quilt-src-components-organisms-media-blocks-pencilbar' */ "./media-blocks/pencilbar"
//   ),
// );
// const Hero = loadable(() =>
//   import(
//     /* webpackChunkName: 'quilt-src-components-organisms-media-blocks-hero' */ "./media-blocks/hero"
//   ),
// );
// import CONSTANTS from "../../constants";

function MediaBlock({
  type,
  image,
  mobileImage,
  shortText,
  longText,
  extraText,
  supportingImage,
  mobileSupportingImage,
  // backgroundImages,
  className,
  id,
  grid,
  props,
  useTitle,
  title,
  lazyLoad,
}) {
  // const [votes, setVotes] = useState();
  const [swatch, setSwatch] = useState();
  let mediaBlockClasses = ["mediablock", `mediablock__${type}`];
  const mediaBlockProps = props;
  const isPreview = Boolean(mediaBlockProps.preview);

  let shortTextHtml = shortText;
  let shortTextTitle = shortText;
  if (shortText && shortText.includes("<br>")) {
    shortTextHtml = shortText.split("<br>").map((text) => (
      <React.Fragment key={slugify(text)}>
        {text}
        <br />
      </React.Fragment>
    ));
    shortTextTitle = shortText.replace("<br>", " ");
  }
  let longTextContent = null;
  if (
    longText &&
    mediaBlockProps.longHtml &&
    isMarkdown(longText, mediaBlockProps.longHtml)
  ) {
    longTextContent = (
      <div
        dangerouslySetInnerHTML={{
          __html: mediaBlockProps.longHtml,
        }}
      />
    );
  } else if (longText) {
    longTextContent = <p>{longText}</p>;
  }
  if (className) {
    mediaBlockClasses = mediaBlockClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }
  mediaBlockClasses = mediaBlockClasses.filter((mbc) => mbc !== "");

  // Setup mobile image breakpoints
  const imageSources =
    mobileImage && image
      ? {
          desktop: image,
          mobile: mobileImage,
        }
      : null;
  const supportingImageSources =
    mobileSupportingImage && supportingImage
      ? {
          desktop: supportingImage,
          mobile: mobileSupportingImage,
        }
      : null;

  // const reviewVote = (review, dir) => {
  //   // console.log("review vote", review, dir);

  //   Axios.post(`https://api.yotpo.com/reviews/${review.id}/vote/${dir}`)
  //     .then(() => {
  //       setVotes({
  //         up: dir === "up" ? votes.up + 1 : votes.up,
  //         down: dir === "down" ? votes.down + 1 : votes.down,
  //       });
  //     })
  //     .catch(error => {
  //       console.log("error", error);
  //     });
  // };

  // useEffect(() => {
  //   if (type === "review" && mediaBlockProps.review) {
  //     setVotes({
  //       up: mediaBlockProps.review.votes_up,
  //       down: mediaBlockProps.review.votes_down,
  //     });
  //   }
  // }, [type, mediaBlockProps]);
  let mediaBlockPreview = null;
  if (mediaBlockProps.preview && typeof mediaBlockProps.preview !== "boolean") {
    mediaBlockClasses.push("mediablock__preview");
    mediaBlockPreview = (
      <button
        className="contentful-preview"
        type="button"
        onClick={mediaBlockProps.preview}>
        preview
      </button>
    );
  }

  const openNewWindow =
    mediaBlockProps.cta &&
    mediaBlockProps.cta.route &&
    isExternalLink(mediaBlockProps.cta.route);
  const targetProps = openNewWindow
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {};

  if (type === "hero") {
    const cta = { ...mediaBlockProps.cta };
    if (!cta || !cta.route) {
      mediaBlockClasses.push("mediablock__hero--no-cta");
    }
    if (mediaBlockProps.useGutters === false) {
      mediaBlockClasses.push("mediablock__hero--main");
    }
    if (mediaBlockProps.backgroundColor) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.backgroundColor}`,
      );
    } else if (mediaBlockProps.color === "light") {
      mediaBlockClasses.push(`mediablock__bg--dark`);
    }
    if (mediaBlockProps.imagePosition && image) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.imagePosition}`,
      );
    }
    let heroLongContent = null;
    if (
      longText &&
      mediaBlockProps.longHtml &&
      isMarkdown(longText, mediaBlockProps.longHtml)
    ) {
      heroLongContent = (
        <div
          className={`hero__long-md hero__long-md--${
            mediaBlockProps.color || "dark"
          }`}
          dangerouslySetInnerHTML={{
            __html: mediaBlockProps.longHtml,
          }}
        />
      );
    } else if (longText) {
      heroLongContent = (
        <div className="hero__long">
          <p
            className={`hero__longtext hero__text-${
              mediaBlockProps.color || "dark"
            }`}>
            {longText}
          </p>
        </div>
      );
    }
    const overlayClasses = listSearch({
      list: mediaBlockClasses,
      search: "overlay",
      init: "hero__overlay",
    });

    return (
      // <Hero>
      <LatticeGrid
        fullWidth={mediaBlockProps.useGutters !== false}
        innerGrid
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {image ? (
          <Image
            image={imageSources || image}
            alt={image.description}
            className="shared__bg-img"
            style={{ position: "absolute" }}
            loading={lazyLoad ? "lazy" : "eager"}
            legacy={isPreview}
            abState={mediaBlockProps.abState}
            id={`${mediaBlockProps.mbId}-main`}
          />
        ) : null}

        {image && overlayClasses ? (
          <div className={overlayClasses.join(" ")} />
        ) : null}
        <Wrapper
          tag={mediaBlockProps.imagePosition ? "div" : null}
          className={`hero__wrapper ${
            mediaBlockProps.imagePosition
              ? `hero__wrapper--${mediaBlockProps.imagePosition}`
              : null
          }`}>
          {mediaBlockProps.displayTitle ? (
            <p
              className={`hero__display-text hero__text hero__text-${
                mediaBlockProps.color || "dark"
              }`}>
              {mediaBlockProps.displayTitle}
            </p>
          ) : null}
          <h1
            className={`hero__text shared__short-text hero__text-${
              mediaBlockProps.color || "dark"
            } ${
              findLongestWord(shortTextHtml) > 10 ? "hero__text-verbose" : ""
            }`}>
            {shortTextHtml}
          </h1>
          {heroLongContent}
          {cta && cta.route ? (
            <Button {...cta} openNewWindow={openNewWindow}>
              {cta.text}
            </Button>
          ) : null}
          {supportingImage ? (
            <Image
              image={supportingImageSources || supportingImage}
              alt={supportingImage.description}
              className="mediablock__hero-sec-img"
              loading={lazyLoad ? "lazy" : "eager"}
              legacy={isPreview}
              abState={mediaBlockProps.abState}
              id={`${mediaBlockProps.mbId}-supp`}
            />
          ) : null}
        </Wrapper>
      </LatticeGrid>
      // </Hero>
    );
  }
  if (type === "product-block" || type === "guide-item") {
    mediaBlockClasses.push(
      `mediablock__${type}-${mediaBlockProps.imagePosition || "right"}`,
    );
    const fullProductBlock = mediaBlockProps.useGutters !== null;
    const BlockTitleTag = "h1";
    let BlockContentTag = "h2";
    if (type === "guide-item") {
      BlockContentTag = "p";
    }
    if (fullProductBlock) {
      mediaBlockClasses.push(`mediablock__${type}--full`);
    }
    let blockContent = null;
    if (
      longText &&
      mediaBlockProps.longHtml &&
      isMarkdown(longText, mediaBlockProps.longHtml)
    ) {
      blockContent = (
        <div
          className="shared__block-text"
          dangerouslySetInnerHTML={{
            __html: mediaBlockProps.longHtml,
          }}
        />
      );
    } else if (longText) {
      blockContent = <BlockContentTag>{longText}</BlockContentTag>;
    }
    const cta = { ...mediaBlockProps.cta };
    let productMedia = null;
    if (mediaBlockProps.video) {
      productMedia = (
        <Video
          url={mediaBlockProps.video}
          type={mediaBlockProps.videoType}
          poster={image && getSrc(image)}
        />
      );
    } else if (image) {
      productMedia = (
        <Image
          image={imageSources || image}
          alt={image.description}
          className={
            isStaticImage(imageSources || image, mediaBlockClasses)
              ? "static-img"
              : ""
          }
          style={{ position: "relative" }}
          loading={lazyLoad ? "lazy" : "eager"}
          legacy={isPreview}
          abState={mediaBlockProps.abState}
        />
      );
    }
    if (!grid) {
      return (
        <div
          className={mediaBlockClasses.join(" ")}
          title={useTitle ? title : null}>
          {mediaBlockPreview}
          {productMedia}
          <div className={`mediablock__${type}-content`}>
            <BlockTitleTag className="shared__short-text">
              {shortTextHtml}
            </BlockTitleTag>
            {blockContent}
            {cta && cta.route ? (
              <Button {...cta} openNewWindow={openNewWindow}>
                {cta.text}
              </Button>
            ) : null}
          </div>
        </div>
      );
    }
    return (
      <LatticeGrid
        fullGrid={fullProductBlock}
        fullWidth={fullProductBlock}
        useGutters={fullProductBlock}
        innerGrid
        className={mediaBlockClasses}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        {productMedia}
        <div className={`mediablock__${type}-content`}>
          <BlockTitleTag className="shared__short-text">
            {shortTextHtml}
          </BlockTitleTag>
          {blockContent}
          {cta ? (
            <Button {...cta} openNewWindow={openNewWindow}>
              {cta.text}
            </Button>
          ) : null}
        </div>
      </LatticeGrid>
    );
  }
  if (type === "press-small") {
    return (
      <div
        className={mediaBlockClasses.join(" ")}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        {mediaBlockProps.svg ? (
          <div className="mediablock__press-small-img">
            {lazyLoad ? (
              <LazyLoadImage
                alt={mediaBlockProps.svgDescription}
                src={mediaBlockProps.svg}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            ) : (
              <img
                src={mediaBlockProps.svg}
                alt={mediaBlockProps.svgDescription}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            )}
          </div>
        ) : null}
        <div className="mediablock__press-small-text">
          <p className="shared__short-text">{shortTextHtml}</p>
        </div>
      </div>
    );
  }
  if (type === "product-card") {
    const cta = { ...mediaBlockProps.cta };
    const mediaBlockRoute = cta.route || "#";
    // const LinkTag = mediaBlockRoute.startsWith("http") ? OutboundLink : "a";
    const LinkTag = "a";

    // Special Images
    let specialImageSources = null;
    if (mediaBlockProps.specialImage && !mediaBlockProps.mobileSpecialImage) {
      specialImageSources = mediaBlockProps.specialImage;
    } else if (
      mediaBlockProps.specialImage &&
      mediaBlockProps.mobileSpecialImage
    ) {
      specialImageSources = {
        desktop: mediaBlockProps.specialImage,
        mobile: mediaBlockProps.mobileSpecialImage,
      };
    }

    const swatchOptions = mediaBlockProps.colors
      ? {
          name: "Color",
          values: mediaBlockProps.colors.map(
            (c) => c.node.shopifyColorOption.color,
          ),
          handlers: mediaBlockProps.colors.map((c) => ({
            name: c.node.shopifyColorOption.color,
            onClick: () => {
              let specialImage = null;
              let swImage = null;
              let swDescription = null;
              let swSupportingImage = null;
              let swSupportingDescription = null;
              if (c.node.specialCollectionImages) {
                specialImage = c.node.specialCollectionImages.find(
                  (sci) =>
                    sci.classNames &&
                    sci.classNames.find(
                      (cn) =>
                        mediaBlockProps.carouselClasses &&
                        mediaBlockProps.carouselClasses.includes(cn),
                    ),
                );
              }
              if (specialImage) {
                swImage = specialImage.mobileImage
                  ? {
                      desktop: specialImage.image,
                      mobile: specialImage.image,
                    }
                  : {
                      desktop: specialImage.image,
                    };
                swDescription = specialImage.image.description;
              } else {
                const cimage =
                  c.node.colorImage ||
                  (c.node.colorImages.length && c.node.colorImages[0]);
                const cMobileImage =
                  c.node.mobileColorImage ||
                  (c.node.mobileColorImages &&
                    c.node.mobileColorImages.length &&
                    c.node.mobileColorImages[0]);
                swImage = cMobileImage
                  ? {
                      desktop: cimage,
                      mobile: cMobileImage,
                    }
                  : {
                      desktop: cimage,
                    };
                swDescription = cimage.description;

                // Show 2nd color image as supporting image if there is one
                if (
                  c.node.colorImages &&
                  c.node.colorImages.length > 1 &&
                  c.node.colorImages[1]
                ) {
                  const cSuppImage = c.node.colorImages[1];
                  swSupportingImage =
                    c.node.mobileColorImages &&
                    c.node.mobileColorImages.length > 1 &&
                    c.node.mobileColorImages[1]
                      ? {
                          desktop: cSuppImage,
                          mobile: c.node.mobileColorImages[1],
                        }
                      : {
                          desktop: cSuppImage,
                        };
                  swSupportingDescription = cSuppImage.description;
                }
              }
              setSwatch({
                image: swImage,
                supportingImage: swSupportingImage,
                description: swDescription,
                supportingDescription: swSupportingDescription,
                color: c.node.shopifyColorOption.color,
              });
            },
          })),
        }
      : null;
    const hasSwatches = mediaBlockProps.colors && swatchOptions;
    if (mediaBlockProps.isBundle) {
      mediaBlockClasses.push("mediablock__product-card--swatch");
    }
    if (hasSwatches) {
      mediaBlockClasses.push("mediablock__product-card--swatch");
      useEffect(() => {
        if (mediaBlockProps.colors && mediaBlockProps.colors.length) {
          let specialImage = null;
          let swImage = null;
          let swDescription = null;
          let swSupportingImage = null;
          let swSupportingDescription = null;
          if (
            mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
              .specialCollectionImages
          ) {
            specialImage = mediaBlockProps.colors[
              mediaBlockProps.defaultColorIndex
            ].node.specialCollectionImages.find(
              (sci) =>
                sci.classNames &&
                sci.classNames.find(
                  (cn) =>
                    mediaBlockProps.carouselClasses &&
                    mediaBlockProps.carouselClasses.includes(cn),
                ),
            );
          }
          if (specialImage) {
            swImage = specialImage.mobileImage
              ? {
                  desktop: specialImage.image,
                  mobile: specialImage.mobileImage,
                }
              : {
                  desktop: specialImage.image,
                };
            swDescription = specialImage.image.description;
          } else {
            const cimage =
              mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .colorImage ||
              (mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .colorImages.length &&
                mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                  .colorImages[0]);
            const cMobileImage =
              mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .mobileColorImage ||
              (mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .mobileColorImages &&
                mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                  .mobileColorImages.length &&
                mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                  .mobileColorImages[0]);
            swImage = cMobileImage
              ? {
                  desktop: cimage,
                  mobile: cMobileImage,
                }
              : {
                  desktop: cimage,
                };
            swDescription = cimage.description;

            // Show 2nd color image as supporting image if there is one
            if (
              mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .colorImages &&
              mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .colorImages.length &&
              mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .colorImages[1]
            ) {
              const cSuppImage =
                mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                  .colorImages[1];
              swSupportingImage =
                mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                  .mobileColorImages &&
                mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                  .mobileColorImages.length &&
                mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                  .mobileColorImages[1]
                  ? {
                      desktop: cSuppImage,
                      mobile:
                        mediaBlockProps.colors[
                          mediaBlockProps.defaultColorIndex
                        ].node.mobileColorImages[1],
                    }
                  : {
                      desktop: cSuppImage,
                    };
              swSupportingDescription = cSuppImage.description;
            }
          }
          setSwatch({
            image: swImage,
            supportingImage: swSupportingImage,
            description: swDescription,
            supportingDescription: swSupportingDescription,
            color:
              mediaBlockProps.colors[mediaBlockProps.defaultColorIndex].node
                .shopifyColorOption.color,
          });
        }
      }, []);
    }
    const { priceInfo, reviewInfo } = mediaBlockProps;
    const usePriceInfo =
      priceInfo &&
      priceInfo.valid &&
      (priceInfo.discountName || priceInfo.bundleDiscountName) &&
      priceInfo.newPrice &&
      !priceInfo.skipDisplay &&
      priceInfo.newPrice !== priceInfo.price;

    const imageEl = (
      <Image
        image={
          (swatch && swatch.image) ||
          specialImageSources ||
          imageSources ||
          image
        }
        alt={
          (swatch && swatch.image && swatch.description) ||
          (mediaBlockProps.specialImage &&
            mediaBlockProps.specialImage.description) ||
          image.description
        }
        className={`${
          hasSwatches
            ? "mediablock__product-card-img-swatch"
            : "mediablock__product-card-img-main"
        } ${
          mediaBlockProps.isGiftCard ? "mediablock__product-card-img-gc" : ""
        } ${
          isStaticImage(
            (swatch && swatch.image) ||
              specialImageSources ||
              imageSources ||
              image,
            mediaBlockClasses,
          ) || mediaBlockProps.isGiftCard
            ? "static-img"
            : ""
        }`}
        style={{ position: "absolute" }}
        loading={lazyLoad ? "lazy" : "eager"}
        legacy={isPreview}
        abState={mediaBlockProps.abState}
      />
    );
    const supportingImageEl = (
      <Image
        image={
          (swatch && swatch.supportingImage) ||
          supportingImageSources ||
          supportingImage
        }
        alt={
          (swatch && swatch.supportingImage && swatch.supportingDescription) ||
          supportingImage.description
        }
        className={
          isStaticImage(
            (swatch && swatch.supportingImage) ||
              supportingImageSources ||
              supportingImage,
            mediaBlockClasses,
          ) || mediaBlockProps.isGiftCard
            ? "static-img"
            : ""
        }
        style={{ position: "absolute" }}
        loading="lazy"
        legacy={isPreview}
        abState={mediaBlockProps.abState}
      />
    );
    return (
      <div
        className={mediaBlockClasses.join(" ")}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        <LinkTag
          title={shortTextTitle}
          aria-label={shortTextTitle}
          href={`${mediaBlockRoute}${
            swatch && swatch.color
              ? `?color=${encodeURIComponent(swatch.color)}`
              : ""
          }`}
          {...targetProps}>
          <div className="mediablock__product-card-img">
            {/* {mediaBlockProps.new ? (
              <Badge color="green" className="product-card__status">
                New
              </Badge>
            ) : null} */}

            {usePriceInfo && priceInfo.discountName ? (
              <Badge color={priceInfo.color} className="shared__discount-badge">
                {priceInfo.discountName}
              </Badge>
            ) : null}

            {mediaBlockProps.useMemo
              ? useMemo(
                  () => imageEl,
                  [
                    swatch && swatch.image,
                    specialImageSources,
                    imageSources,
                    image,
                  ],
                )
              : imageEl}

            <div
              className={`mediablock__product-card-img-hover ${
                mediaBlockProps.isGiftCard
                  ? "mediablock__product-card-img-gc"
                  : ""
              }`}>
              {mediaBlockProps.useMemo
                ? useMemo(
                    () => supportingImageEl,
                    [
                      swatch && swatch.supportingImage,
                      supportingImageSources,
                      supportingImage,
                    ],
                  )
                : supportingImageEl}
            </div>
          </div>
          <h2 className="shared__short-text">{shortTextHtml}</h2>
          {usePriceInfo ? (
            <div className="plp__discount-info">
              <p className="extra-text">
                From <del aria-hidden="true">${priceInfo.price}</del>{" "}
                <ins>${priceInfo.newPrice}</ins>
              </p>
            </div>
          ) : null}
          {extraText && !usePriceInfo ? (
            <p className="extra-text">{extraText}</p>
          ) : null}
          {reviewInfo && reviewInfo.details ? (
            <div className="plp__review-info">
              <div className="plp__review-stars">
                <Image
                  image={reviewInfo.reviewStars}
                  alt="Review stars."
                  loading="lazy"
                  legacy={isPreview}
                  abState={mediaBlockProps.abState}
                />
              </div>
              <p>
                <ins>{reviewInfo.details.numReviews.toLocaleString()}</ins>
                <ins>Reviews</ins>
              </p>
            </div>
          ) : null}
          {!hasSwatches ||
          (hasSwatches &&
            mediaBlockProps.colors &&
            !mediaBlockProps.colors.length) ||
          (mediaBlockProps.carouselClasses &&
            mediaBlockProps.carouselClasses.includes("show-summary")) ? (
            <p className="summary-text">{longText}</p>
          ) : null}
        </LinkTag>
        {hasSwatches &&
        mediaBlockProps.colors &&
        mediaBlockProps.colors.length ? (
          <>
            <SwatchGroup
              options={swatchOptions}
              hideOptionTitle
              activeIndex={mediaBlockProps.defaultColorIndex}
              // variants={panelVariants}
              // activeVariant={panelActive}
              // setActiveVariant={setActiveVariant}
              colorInfo={mediaBlockProps.colors}
              size="small"
              minOverflow={11}
              hideOptions={mediaBlockProps.hideOptions}
              // key={o.name}
            />
            <p className="swatch__group-info">
              {mediaBlockProps.colors.length} colors
            </p>
          </>
        ) : null}
      </div>
    );
  }
  if (type === "card") {
    const cta = { ...mediaBlockProps.cta };
    const mediaBlockRoute = cta.route || null;
    const CardTag = mediaBlockRoute ? "a" : "div";
    const cardTagProps = mediaBlockRoute
      ? {
          ...targetProps,
          href: mediaBlockRoute,
          title: shortTextTitle,
        }
      : {
          title: shortTextTitle,
        };

    if (!mediaBlockProps.useSlider) {
      mediaBlockClasses.push("mediablock__card--long");
    }
    // mediaBlockClasses.push(
    //   `mediablock__card--${mediaBlockProps.imagePosition}`,
    // );
    return (
      <div
        className={mediaBlockClasses.join(" ")}
        title={useTitle ? title : null}>
        {mediaBlockPreview}

        <CardTag {...cardTagProps}>
          <div
            className={`mediablock__card-img${
              !mediaBlockProps.useSlider ? "-long" : ""
            }`}>
            <Image
              image={imageSources || image}
              alt={image.description}
              className={`mediablock__card-img-main${
                !mediaBlockProps.useSlider ? "-long" : ""
              }`}
              loading={lazyLoad ? "lazy" : "eager"}
              legacy={isPreview}
              abState={mediaBlockProps.abState}
            />
          </div>
          <div className="mediablock__card-content">
            <h2 className="shared__short-text">{shortTextHtml}</h2>
            {mediaBlockProps.displayTitle ? (
              <h2 className="shared__short-text">
                {mediaBlockProps.displayTitle}
              </h2>
            ) : null}
            {longTextContent}
          </div>
          {cta ? (
            <div
              className={`mediablock__card-link shared__button shared__button--${
                cta.color || "eucalyptus"
              }`}>
              <span>
                {cta.text}
                {cta.label ? (
                  <aside className="button-aside" role="none">
                    {" "}
                    {cta.label}
                  </aside>
                ) : null}
              </span>
            </div>
          ) : null}
        </CardTag>
      </div>
    );
  }
  if (type === "sticker") {
    mediaBlockClasses.push("mediablock__sticker");
    return (
      <LatticeGrid
        innerGrid
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {mediaBlockProps.svg ? (
          <div className="mediablock__sticker-wrapper">
            <div
              className={`mediablock__sticker-img mediablock__sticker-img--${
                mediaBlockProps.imagePosition || "left"
              }`}>
              {lazyLoad ? (
                <LazyLoadImage
                  alt={mediaBlockProps.svgDescription}
                  src={mediaBlockProps.svg}
                  importance={lazyLoad ? "low" : "high"}
                />
              ) : (
                <img
                  src={mediaBlockProps.svg}
                  alt={mediaBlockProps.svgDescription}
                  importance={lazyLoad ? "low" : "high"}
                />
              )}
            </div>
          </div>
        ) : null}
      </LatticeGrid>
    );
  }
  if (type === "ticker") {
    mediaBlockClasses.push("mediablock__ticker");
    if (mediaBlockProps.svg) {
      mediaBlockClasses.push("mediablock__ticker--icon");
    }
    // if (mediaBlockProps.backgroundColor) {
    //   mediaBlockClasses.push(
    //     `mediablock__bg--${mediaBlockProps.backgroundColor}`,
    //   );
    // }
    const numIcons = 12;
    const numTexts = 2;
    return (
      <MediaCarousel
        className={mediaBlockClasses}
        backgroundColor={mediaBlockProps.backgroundColor}
        props={{
          preview: mediaBlockProps.preview,
        }}
        {...getCarouselSettings("ticker", {
          icon: mediaBlockProps.svg,
          iconWidth: mediaBlockProps.width,
          iconHeight: mediaBlockProps.height,
          text: shortText,
        })}
        forceRender
        useSlider>
        {mediaBlockProps.svg
          ? [...Array(numIcons)].map((e, i) => {
              const iconId = `ticker-icon-${i}`;
              return lazyLoad ? (
                <LazyLoadImage
                  className="mediablock__ticker-img"
                  alt={mediaBlockProps.svgDescription}
                  src={mediaBlockProps.svg}
                  key={iconId}
                  importance={lazyLoad ? "low" : "high"}
                />
              ) : (
                <img
                  className="mediablock__ticker-img"
                  alt={mediaBlockProps.svgDescription}
                  src={mediaBlockProps.svg}
                  key={iconId}
                  importance={lazyLoad ? "low" : "high"}
                />
              );
            })
          : [...Array(numTexts)].map((e, i) => {
              const textId = `ticker-text-${i}`;
              return (
                <p className="mediablock__ticker-text" key={textId}>
                  {shortText}
                </p>
              );
            })}
      </MediaCarousel>
    );
  }
  if (type === "email-hero") {
    // mediaBlockClasses.push("mediablock__hero");
    if (mediaBlockProps.useGutters === false) {
      mediaBlockClasses.push("mediablock__email-hero--main");
    }
    if (mediaBlockProps.backgroundColor) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.backgroundColor}`,
      );
    }
    return (
      <LatticeGrid
        fullWidth={mediaBlockProps.useGutters !== false}
        innerGrid
        title={useTitle ? title : null}
        className={mediaBlockClasses}
        id={id}>
        {mediaBlockPreview}
        <h1
          className={`email-hero__text shared__short-text email-hero__text-${
            mediaBlockProps.color || "dark"
          }`}>
          {shortTextHtml}
        </h1>
        {longText ? (
          <div className="email-hero__long">
            <p
              className={`email-hero__longtext email-hero__text-${
                mediaBlockProps.color || "dark"
              }`}>
              {longText}
            </p>
          </div>
        ) : null}
        <EmailSubscribe
          label={`${id}-input`}
          title={title}
          className="email-hero__email-subscribe email-hero__email-hide-title"
          list={mediaBlockProps.oosEmailList || process.env.OOS_EMAIL_LIST}
          messageColor={mediaBlockProps.color || null}
          extras={mediaBlockProps.extras}
        />
      </LatticeGrid>
    );
  }
  if (type === "review") {
    const review = { ...mediaBlockProps.review };
    const reviewModal = { ...mediaBlockProps.reviewModal };
    // setVotes({
    //   up: review.votes_up,
    //   down: review.votes_down,
    // });
    // console.log("review votes...", review.votes_up, review.votes_down, votes);
    if (review.content) {
      const stars = [];

      for (let i = 0; i < review.score; i += 1) {
        stars.push(
          <Image
            image={mediaBlockProps.reviewStar}
            alt="Star to signify a review."
            key={`star-${i}`}
            loading="lazy"
            legacy={isPreview}
            abState={mediaBlockProps.abState}
          />,
        );
      }
      const reviewContent = (
        <>
          <div className="mediablock__reivew-stars">{stars}</div>
          <p className="mediablock__review-name">{review.user.display_name}</p>
          <p className="mediablock__review-date">
            {format(new Date(review.created_at), "MM/dd/yyyy")}
          </p>
          <h2
            className="mediablock__review-headline"
            dangerouslySetInnerHTML={{
              __html: review.title,
            }}
          />
          <p
            className="mediablock__review-description"
            dangerouslySetInnerHTML={{
              __html: review.content,
            }}
          />
        </>
      );
      return (
        <>
          <div className="mediablock__review" title={useTitle ? title : null}>
            <div className="mediablock__review-content">{reviewContent}</div>
          </div>
          <div className="mediablock__review-panel">
            <div
              className="mediablock__review-more"
              aria-hidden="true"
              title={`${stripHtml(review.title)} - Read more`}
              onClick={(e) => {
                e.preventDefault();
                reviewModal.setReviewModalContent(reviewContent);
                reviewModal.setShowReviewModal(true);
              }}
              onKeyPress={(e) => {
                e.preventDefault();
                reviewModal.setReviewModalContent(reviewContent);
                reviewModal.setShowReviewModal(true);
              }}>
              <span>
                Read more
                <aside className="button-aside" role="none">
                  Open modal for review titled &quot;
                  {stripHtml(review.title)}&quot;
                </aside>
              </span>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
  if (type === "trial") {
    let trialLongContent = null;
    if (
      longText &&
      mediaBlockProps.longHtml &&
      isMarkdown(longText, mediaBlockProps.longHtml)
    ) {
      trialLongContent = (
        <div
          dangerouslySetInnerHTML={{
            __html: mediaBlockProps.longHtml,
          }}
        />
      );
    } else if (longText) {
      trialLongContent = <p>{longText}</p>;
    }
    return (
      <div
        className={mediaBlockClasses.join(" ")}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        {mediaBlockProps.svg ? (
          <div className="mediablock__trial-img">
            {lazyLoad ? (
              <LazyLoadImage
                alt={mediaBlockProps.svgDescription}
                src={mediaBlockProps.svg}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            ) : (
              <img
                src={mediaBlockProps.svg}
                alt={mediaBlockProps.svgDescription}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            )}
          </div>
        ) : null}
        <div className="mediablock__trial-text">
          {shortTextHtml ? (
            <h2 className="shared__short-text">{shortTextHtml}</h2>
          ) : null}
          {trialLongContent}
        </div>
      </div>
    );
  }
  if (type === "ksp") {
    return (
      <div
        className={mediaBlockClasses.join(" ")}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        {mediaBlockProps.svg ? (
          <div className="mediablock__ksp-img">
            {lazyLoad ? (
              <LazyLoadImage
                alt={mediaBlockProps.svgDescription}
                src={mediaBlockProps.svg}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            ) : (
              <img
                src={mediaBlockProps.svg}
                alt={mediaBlockProps.svgDescription}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            )}
          </div>
        ) : null}
        <div className="mediablock__ksp-text">
          <p className="shared__short-text">{shortTextHtml}</p>
        </div>
      </div>
    );
  }
  if (type === "quote-hero") {
    if (mediaBlockProps.imagePosition && image) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.imagePosition}`,
      );
    }
    if (mediaBlockProps.color === "light") {
      mediaBlockClasses.push(`mediablock__bg--dark`);
    }
    const overlayClasses = listSearch({
      list: mediaBlockClasses,
      search: "overlay",
      init: "hero__overlay",
    });
    return (
      <LatticeGrid
        fullWidth
        innerGrid
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {image ? (
          <Image
            image={imageSources || image}
            alt={image.description}
            className="shared__bg-img"
            style={{ position: "absolute" }}
            loading={lazyLoad ? "lazy" : "eager"}
            legacy={isPreview}
            abState={mediaBlockProps.abState}
          />
        ) : null}
        {image && overlayClasses ? (
          <div className={overlayClasses.join(" ")} />
        ) : null}
        <div
          className={`quote-hero__wrapper ${
            mediaBlockProps.textPosition
              ? `quote__${mediaBlockProps.textPosition}`
              : ""
          }`}>
          <h1
            className={`quote__text shared__short-text quote__text-${
              mediaBlockProps.color || "dark"
            }`}>
            {shortTextHtml}
          </h1>
          {mediaBlockProps.svg ? (
            <div
              className={`mediablock__quote-hero-img mediablock__quote-hero-img--${
                mediaBlockProps.color || "dark"
              }`}>
              {lazyLoad ? (
                <LazyLoadImage
                  alt={mediaBlockProps.svgDescription}
                  src={mediaBlockProps.svg}
                  width={mediaBlockProps.width}
                  height={mediaBlockProps.height}
                  importance={lazyLoad ? "low" : "high"}
                />
              ) : (
                <img
                  src={mediaBlockProps.svg}
                  alt={mediaBlockProps.svgDescription}
                  width={mediaBlockProps.width}
                  height={mediaBlockProps.height}
                  importance={lazyLoad ? "low" : "high"}
                />
              )}
            </div>
          ) : null}
          {supportingImage ? (
            <Image
              image={supportingImageSources || supportingImage}
              alt={supportingImage.description}
              className="mediablock__quote-hero-supp-img"
              style={{ display: "block" }}
              legacy={isPreview}
              loading={lazyLoad ? "lazy" : "eager"}
              abState={mediaBlockProps.abState}
            />
          ) : null}
          {longText ? (
            <p
              className={`quote__text-credit quote__text-${
                mediaBlockProps.color || "dark"
              }`}>
              {longText}
            </p>
          ) : null}
        </div>
      </LatticeGrid>
    );
  }
  if (type === "story-block") {
    mediaBlockClasses.push(
      `mediablock__${type}-${mediaBlockProps.imagePosition || "right"}`,
    );
    if (mediaBlockProps.backgroundColor) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.backgroundColor}`,
      );
    }

    let storyMedia = null;
    if (mediaBlockProps.video) {
      storyMedia = (
        <Video
          url={mediaBlockProps.video}
          type={mediaBlockProps.videoType}
          poster={image && getSrc(image)}
        />
      );
    } else if (image) {
      storyMedia = (
        <Image
          image={imageSources || image}
          alt={image.description}
          className={
            isStaticImage(imageSources || image, mediaBlockClasses)
              ? "static-img"
              : ""
          }
          loading={lazyLoad ? "lazy" : "eager"}
          legacy={isPreview}
          abState={mediaBlockProps.abState}
        />
      );
    }
    return (
      <LatticeGrid
        fullWidth={!!mediaBlockProps.backgroundColor}
        innerGrid
        className={mediaBlockClasses}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        {storyMedia}
        <div className="mediablock__story-block-content">
          <h2 className="shared__short-text">{shortTextHtml}</h2>
          {longTextContent}
        </div>
      </LatticeGrid>
    );
  }
  if (type === "related-block") {
    const cta = { ...mediaBlockProps.cta };
    let relatedContent = null;

    if (longText && isMarkdown(longText, mediaBlockProps.longHtml)) {
      relatedContent = (
        <div
          className={`free-block-section__text free-block-section__text--long free-block-section__text--${
            mediaBlockProps.color || "dark"
          }`}
          dangerouslySetInnerHTML={{
            __html: mediaBlockProps.longHtml,
          }}
        />
      );
    } else if (longText) {
      relatedContent = (
        <p
          className={`free-block__text free-block__text--long free-block__text--${
            mediaBlockProps.color || "dark"
          }`}>
          {longText}
        </p>
      );
    }

    let relatedImage = null;
    if (image) {
      if (cta && cta.route) {
        relatedImage = (
          <a
            href={cta.route}
            title={shortTextTitle || image.title}
            {...targetProps}>
            <Image
              image={imageSources || image}
              alt={image.description}
              className="mediablock__free-block-sec-img"
              loading={lazyLoad ? "lazy" : "eager"}
              legacy={isPreview}
              abState={mediaBlockProps.abState}
            />
          </a>
        );
      } else {
        relatedImage = (
          <Image
            image={imageSources || image}
            alt={image.description}
            className="mediablock__free-block-sec-img"
            loading={lazyLoad ? "lazy" : "eager"}
            legacy={isPreview}
            abState={mediaBlockProps.abState}
          />
        );
      }
    }
    const relatedBlock = (
      <div
        className={`free-block__wrapper ${
          !grid ? mediaBlockClasses.join(" ") : ""
        }`}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        {relatedImage}
        {shortText ? (
          <p
            className={`free-block__text shared__short-text free-block__text--short free-block__text--${
              mediaBlockProps.color || "dark"
            }`}>
            {cta && cta.route ? (
              <a
                href={cta.route}
                {...targetProps}
                title={shortTextTitle || image.title}
                {...targetProps}>
                {shortTextHtml}
              </a>
            ) : (
              shortTextHtml
            )}
          </p>
        ) : null}
        {!shortText && cta && cta.route ? (
          <p
            className={`free-block__text shared__short-text free-block__text--short free-block__text--${
              mediaBlockProps.color || "dark"
            }`}>
            <a href={cta.route} title={cta.text} {...targetProps}>
              {cta.text}
            </a>
          </p>
        ) : null}
        {relatedContent}
      </div>
    );
    return relatedBlock;
  }
  if (type === "free-block") {
    const cta = { ...mediaBlockProps.cta };
    let freeContent = null;
    if (mediaBlockProps.imagePosition && image) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.imagePosition}`,
      );
    }
    if (mediaBlockProps.backgroundColor) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.backgroundColor}`,
      );
    }
    if (image) {
      mediaBlockClasses.push(`mediablock__free-block--image`);
    }

    if (longText && isMarkdown(longText, mediaBlockProps.longHtml)) {
      freeContent = (
        <div
          className={`free-block-section__text free-block-section__text--long free-block-section__text--${
            mediaBlockProps.color || "dark"
          }`}
          dangerouslySetInnerHTML={{
            __html: mediaBlockProps.longHtml,
          }}
        />
      );
    } else if (longText) {
      freeContent = (
        <p
          className={`free-block__text free-block__text--long free-block__text--${
            mediaBlockProps.color || "dark"
          }`}>
          {longText}
        </p>
      );
    }
    const freeBlock = (
      <div
        className={`free-block__wrapper ${
          !grid ? mediaBlockClasses.join(" ") : ""
        }`}
        title={useTitle ? title : null}>
        {mediaBlockPreview}
        {!grid && image ? (
          <Image
            image={imageSources || image}
            alt={image.description}
            className="free-block__bg"
            loading={lazyLoad ? "lazy" : "eager"}
            style={mediaBlockProps.imageStyle || null}
            legacy={isPreview}
            abState={mediaBlockProps.abState}
          />
        ) : null}
        {shortText ? (
          <h1
            className={`free-block__text shared__short-text free-block__text--short free-block__text--${
              mediaBlockProps.color || "dark"
            }`}>
            {shortTextHtml}
          </h1>
        ) : null}
        {mediaBlockProps.displayTitle ? (
          <h2
            className={`free-block__text shared__display-text free-block__text--display free-block__text--${
              mediaBlockProps.color || "dark"
            }`}>
            {mediaBlockProps.displayTitle}
          </h2>
        ) : null}
        {supportingImage ? (
          <Image
            image={supportingImageSources || supportingImage}
            alt={supportingImage.description}
            className="mediablock__free-block-sec-img"
            loading={lazyLoad ? "lazy" : "eager"}
            style={mediaBlockProps.supporintgImageStyle || null}
            legacy={isPreview}
            abState={mediaBlockProps.abState}
          />
        ) : null}
        {mediaBlockProps.svg ? (
          <div className="mediablock__free-block-img">
            {lazyLoad ? (
              <LazyLoadImage
                alt={mediaBlockProps.svgDescription}
                src={mediaBlockProps.svg}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            ) : (
              <img
                src={mediaBlockProps.svg}
                alt={mediaBlockProps.svgDescription}
                width={mediaBlockProps.width}
                height={mediaBlockProps.height}
                importance={lazyLoad ? "low" : "high"}
              />
            )}
          </div>
        ) : null}
        {freeContent}
        {cta && cta.route ? (
          <Button {...cta} openNewWindow={openNewWindow}>
            {cta.text}
          </Button>
        ) : null}
      </div>
    );
    if (!grid) {
      return freeBlock;
    }
    const overlayClasses = listSearch({
      list: mediaBlockClasses,
      search: "overlay",
      init: "hero__overlay",
    });
    return (
      <LatticeGrid
        fullWidth
        innerGrid
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {grid && image ? (
          <Image
            image={imageSources || image}
            alt={image.description}
            className="shared__bg-img"
            style={{ position: "absolute" }}
            loading={lazyLoad ? "lazy" : "eager"}
            legacy={isPreview}
            abState={mediaBlockProps.abState}
          />
        ) : null}
        {image &&
        overlayClasses &&
        mediaBlockClasses.includes("mediablock__full-review") ? (
          <div className={overlayClasses.join(" ")} />
        ) : null}
        {freeBlock}
      </LatticeGrid>
    );
  }
  if (type === "info-block") {
    mediaBlockClasses.push("info__accordion");
    return (
      <Accordion
        title={shortTextTitle}
        className={mediaBlockClasses}
        isHtml
        tag={mediaBlockProps.tag || "h2"}
        iconColor={mediaBlockProps.iconColor}
        props={{ preview: mediaBlockProps.preview }}>
        {longText}
      </Accordion>
    );
  }
  if (type === "video") {
    mediaBlockClasses.push("mediablock__video");
    return (
      <LatticeGrid
        fullWidth
        fullGrid
        innerGrid
        useGutters
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        <Video
          url={mediaBlockProps.video}
          type={mediaBlockProps.type}
          poster={image && getSrc(image)}
        />
      </LatticeGrid>
    );
  }
  if (type === "image") {
    mediaBlockClasses.push("mediablock__image");
    if (mediaBlockProps.backgroundColor) {
      mediaBlockClasses.push(
        `mediablock__bg--${mediaBlockProps.backgroundColor}`,
      );
    }
    if (isStaticImage(imageSources || image, mediaBlockClasses)) {
      mediaBlockClasses.push(`mediablock__image--static`);
    }
    return (
      <LatticeGrid
        fullWidth
        fullGrid
        innerGrid
        useGutters
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {image ? (
          <Image
            image={imageSources || image}
            alt={image.description}
            className={`shared__bg-img ${
              isStaticImage(imageSources || image, mediaBlockClasses)
                ? "static-img"
                : ""
            }`}
            style={{ position: "absolute" }}
            loading={lazyLoad ? "lazy" : "eager"}
            legacy={isPreview}
            abState={mediaBlockProps.abState}
          />
        ) : null}
      </LatticeGrid>
    );
  }
  if (type === "editorial" || type === "large-editorial") {
    mediaBlockClasses.push("mediablock__editorial");
    if (type === "large-editorial") {
      mediaBlockClasses.push("mediablock__large-editorial");
    }
    if (mediaBlockProps.backgroundColor) {
      mediaBlockClasses.push(
        `mediablock__editorial--${mediaBlockProps.backgroundColor}`,
      );
    }
    let editorialContent = null;
    if (longText && isMarkdown(longText, mediaBlockProps.longHtml)) {
      editorialContent = (
        <div
          className={`free-block-section__text free-block-section__text--long free-block-section__text--${
            mediaBlockProps.color || "dark"
          }`}
          dangerouslySetInnerHTML={{
            __html: mediaBlockProps.longHtml,
          }}
        />
      );
    } else if (longText) {
      editorialContent = (
        <p className="free-block__text free-block__text--long">{longText}</p>
      );
    }
    const freeBlock = (
      <div className="free-block__wrapper" title={useTitle ? title : null}>
        {editorialContent}
      </div>
    );
    return (
      <LatticeGrid
        fullWidth
        innerGrid
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {freeBlock}
      </LatticeGrid>
    );
  }
  if (type === "text") {
    mediaBlockClasses.push("mediablock__text");
    if (mediaBlockProps.backgroundColor) {
      mediaBlockClasses.push(
        `mediablock__text--${mediaBlockProps.backgroundColor}`,
      );
    }
    let textContent = null;
    if (longText && isMarkdown(longText, mediaBlockProps.longHtml)) {
      textContent = (
        <div
          className={`free-block-section__text free-block-section__text--long free-block-section__text--${
            mediaBlockProps.color || "dark"
          }`}
          dangerouslySetInnerHTML={{
            __html: mediaBlockProps.longHtml,
          }}
        />
      );
    } else if (longText) {
      textContent = (
        <p className="free-block__text free-block__text--long">{longText}</p>
      );
    }
    const freeBlock = (
      <div className="free-block__wrapper" title={useTitle ? title : null}>
        {textContent}
      </div>
    );
    return (
      <LatticeGrid
        fullWidth
        innerGrid
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {freeBlock}
      </LatticeGrid>
    );
  }
  if (type === "lifestyle") {
    return (
      <>
        {mediaBlockPreview}
        <Image
          image={imageSources || image}
          alt={image.description}
          className="lifestyle-slide"
          loading={lazyLoad ? "lazy" : "eager"}
          legacy={isPreview}
          abState={mediaBlockProps.abState}
        />
      </>
    );
  }
  if (type === "pencilbar") {
    const cta = { ...mediaBlockProps.cta };
    if (cta.color) {
      mediaBlockClasses.push(`mediablock__bg--${cta.color}`);
    }
    const underlines = cta.text.match(/<u>(.*?)<\/u>/g);
    let ctaSpan = (
      <span className={cta.text.length > 85 ? "small" : ""}>{cta.text}</span>
    );
    if (underlines) {
      ctaSpan = (
        <span
          className={cta.text.length > 85 ? "small" : ""}
          dangerouslySetInnerHTML={{
            __html: cta.text,
          }}
        />
      );
    }
    return (
      // <Pencilbar>
      <LatticeGrid
        fullWidth
        fullGrid
        innerGrid
        useGutters
        role="region"
        title={useTitle ? title : null}
        className={mediaBlockClasses}>
        {mediaBlockPreview}
        {cta.route ? (
          <a className="shared__text" href={cta.route} {...targetProps}>
            {ctaSpan}
            {mediaBlockProps.displayTitle ? (
              <Badge color={cta.color}>{mediaBlockProps.displayTitle}</Badge>
            ) : null}
          </a>
        ) : (
          <>
            <p
              className={`shared__text ${cta.text.length > 85 ? "small" : ""}`}>
              {cta.text}
            </p>
            {mediaBlockProps.displayTitle ? (
              <Badge color={cta.color} className="shared__badge--pencilbar">
                {mediaBlockProps.displayTitle}
              </Badge>
            ) : null}
          </>
        )}
      </LatticeGrid>
      // </Pencilbar>
    );
  }

  return null;
}

// MediaBlock.propTypes = {
//   type: PropTypes.string,
//   // mobileImage: PropTypes.shape({
//   //  gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
//   // }),
//   image: PropTypes.shape({
//     gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
//     title: PropTypes.string,
//   }),
//   mobileImage: PropTypes.shape({
//     gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
//     title: PropTypes.string,
//   }),
//   supportingImage: PropTypes.shape({
//     gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
//     title: PropTypes.string,
//   }),
//   mobileSupportingImage: PropTypes.shape({
//     gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
//     title: PropTypes.string,
//   }),
//   shortText: PropTypes.string,
//   longText: PropTypes.string,
//   extraText: PropTypes.string,
//   props: PropTypes.objectOf(
//     PropTypes.oneOfType([
//       PropTypes.string,
//       PropTypes.func,
//       PropTypes.bool,
//       PropTypes.number,
//       PropTypes.objectOf(
//         PropTypes.oneOfType([
//           PropTypes.string,
//           PropTypes.func,
//           PropTypes.bool,
//           PropTypes.number,
//           PropTypes.object,
//         ]),
//       ),
//       PropTypes.arrayOf(PropTypes.any),
//     ]),
//   ),
//   // backgroundImages: PropTypes.arrayOf(
//   //   PropTypes.shape({
//   //     src: PropTypes.string.isRequired,
//   //   }),
//   // ),
//   className: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.arrayOf(PropTypes.string),
//   ]),
//   id: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.arrayOf(PropTypes.string),
//   ]),
//   grid: PropTypes.bool,
//   useTitle: PropTypes.bool,
//   title: PropTypes.string,
// };

// MediaBlock.defaultProps = {
//   type: "hero",
//   // mobileImage: null,
//   image: null,
//   mobileImage: null,
//   supportingImage: null,
//   mobileSupportingImage: null,
//   shortText: "",
//   longText: "",
//   extraText: "",
//   props: {},
//   // backgroundImages: null,
//   className: "",
//   id: "",
//   grid: true,
//   useTitle: false,
//   title: null,
// };

MediaBlock.designSystemProps = {
  type: {
    type: "string",
    description: `Type of block ("hero" | "product-block" | "press-small" | "trial" | "ksp" | "quote-hero" | "story-block" | "review" | "free-block" | "info-block" | "video" | "editorial" | "lifestyle" | "pencilbar" | "large-editorial" | "image" | "email-hero" | "text" | "guide-item" | "related-block" | "card" | "sticker" | "chart")`,
    propType: PropTypes.string,
    default: "hero",
    required: false,
  },
  image: {
    type: "object",
    description: "Main image object.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
      title: PropTypes.string,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
      title: {
        type: "string",
        description: "Image title.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
  mobileImage: {
    type: "object",
    description: "Main mobile image object.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
      title: PropTypes.string,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
      title: {
        type: "string",
        description: "Image title.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
  supportingImage: {
    type: "object",
    description: "Supporting image object.",
    propType: PropTypes.oneOfType([
      PropTypes.shape({
        gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string,
      }),
      PropTypes.shape({
        file: PropTypes.objectOf(PropTypes.any).isRequired,
      }),
    ]),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
      title: {
        type: "string",
        description: "Image title.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
  mobileSupportingImage: {
    type: "object",
    description: "Supporting mobile image object.",
    propType: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
      title: PropTypes.string,
    }),
    subProps: {
      gatsbyImageData: {
        type: "object",
        description: "Image object.",
        required: true,
      },
      title: {
        type: "string",
        description: "Image title.",
        required: false,
      },
    },
    default: null,
    required: false,
  },
  shortText: {
    type: "string",
    description: "Headline text.",
    propType: PropTypes.string,
    default: "",
    required: false,
  },
  longText: {
    type: "string",
    description: "Description or longer paragraph text.",
    propType: PropTypes.string,
    default: "",
    required: false,
  },
  extraText: {
    type: "string",
    description: "Additional text to display.",
    propType: PropTypes.string,
    default: "",
    required: false,
  },
  props: {
    type: "object",
    description: "Additional media block information.",
    propType: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.object,
          ]),
        ),
        PropTypes.arrayOf(PropTypes.any),
      ]),
    ),
    subProps: {
      cta: {
        type: "object",
        description: "Call to action info.",
        required: true,
        subProps: {
          route: {
            type: "string",
            description: "URL route link.",
            required: false,
          },
        },
      },
      color: {
        type: "string",
        description: `Text color ("dark" | "light")`,
        required: false,
      },
      useGutters: {
        type: "boolean",
        description: "Uses main screen instead of full screen width if true.",
        required: false,
      },
      backgroundColor: {
        type: "string",
        description: "Background color of media block.",
        required: false,
      },
      imagePosition: {
        type: "string",
        description: `Image position ("left" | "right")`,
        required: false,
      },
      displayTitle: {
        type: "string",
        description: "Optional title to use for display purposes.",
        required: false,
      },
      svg: {
        type: "string",
        description: "URL for SVG source.",
        required: false,
      },
    },
    default: {},
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    default: "",
    required: false,
  },
  id: {
    type: "string",
    description: "Unique identifier for this element.",
    propType: PropTypes.string,
    default: "",
    required: false,
  },
  grid: {
    type: "boolean",
    description: "Utilizes Lattice grid for media block if true.",
    propType: PropTypes.bool,
    default: true,
    required: false,
  },
  useTitle: {
    type: "boolean",
    description: "Display title attribute with title property if true.",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  title: {
    type: "string",
    description:
      "Title to use for accessibility or display if shortText is not available.",
    propType: PropTypes.string,
    default: null,
    required: false,
  },
  lazyLoad: {
    type: "boolean",
    description: "Lazy load media block image if true.",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(MediaBlock.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
MediaBlock.propTypes = { ...propTypes };
MediaBlock.defaultProps = { ...defaultProps };

MediaBlock.displayName = "MediaBlock";

export default MediaBlock;
