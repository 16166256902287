import React, { useState } from "react";
import PropTypes from "prop-types";

function Input({
  color,
  // onClick,
  className,
  forwardRef,
  placeholder,
  title,
  type,
  id,
  name,
  onChange,
  value,
  maxLength,
  icon,
  onIconClick,
}) {
  let classList = ["shared__input", `shared__input--${color}`];
  const [fallbackValue, setFallbackValue] = useState(value);
  // const inactiveClick = e => {
  //   e.preventDefault();
  // };
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  const charsLeft = maxLength
    ? maxLength - (value || fallbackValue).length
    : null;
  const Icon = icon;
  return (
    <div
      className={`shared__input-wrapper ${
        icon ? "shared__input-wrapper--icon" : ""
      }`}>
      <label
        htmlFor={id}
        className={`shared__input-label shared__input-label--${color} ${
          fallbackValue ? "shared__input-label--show" : ""
        }`}>
        {title}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        ref={forwardRef}
        value={onChange ? value : fallbackValue}
        className={`${classList.join(" ")} ${
          fallbackValue ? "" : "shared__input--init"
        } ${maxLength ? "shared__input--sp" : ""} ${
          icon ? "shared__input--icon" : ""
        }`}
        onChange={onChange || ((e) => setFallbackValue(e.target.value))}
        // onChange={e => setValue(e.target.value)}
      />
      {icon ? (
        <Icon
          width={24}
          onClick={onIconClick}
          className={onIconClick ? "shared__icon--clickable" : null}
        />
      ) : null}
      {maxLength ? (
        <span
          className={`shared__input-max ${
            charsLeft < 0 ? "shared__input-max--error" : ""
          }`}>
          {charsLeft} characters remaining...
        </span>
      ) : null}
    </div>
  );
}

Input.designSystemProps = {
  color: {
    type: "string",
    description: "Input border color.",
    default: "defaultText",
    propType: PropTypes.string,
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  forwardRef: {
    type: ["function", "object"],
    description: "Ref to component or DOM element.",
    subProps: {
      current: {
        type: ["any"],
        description: "DOM node of Ref object",
        required: false,
        default: null,
      },
    },
    required: false,
    default: null,
    propType: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
  },
  id: {
    type: "string",
    description: "Unique identifier for this element.",
    required: true,
    propType: PropTypes.string.isRequired,
  },
  name: {
    type: "string",
    propType: PropTypes.string.isRequired,
    description: "Name of input element.",
    required: true,
  },
  type: {
    type: "string",
    propType: PropTypes.string,
    description: "Type of input element.",
    required: false,
    default: "email",
  },
  title: {
    propType: PropTypes.string,
    description: "Title of input label.",
    required: false,
    default: null,
  },
  placeholder: {
    type: "string",
    propType: PropTypes.string,
    description: "Placeholder text if input is empty.",
    required: false,
    default: "",
  },
  onChange: {
    type: "func",
    propType: PropTypes.func,
    description: "Function that executes when input changes.",
    required: false,
    default: null,
  },
  value: {
    type: ["string", "number"],
    propType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: "Initial value of input.",
    required: false,
    default: "",
  },
  maxLength: {
    type: "number",
    propType: PropTypes.number,
    description: "Max number of characters allowed for input.",
    required: false,
    default: 0,
  },
  icon: {
    type: "element",
    propType: PropTypes.elementType,
    description: "Icon included in input box.",
    required: false,
    default: null,
  },
  onIconClick: {
    type: "func",
    propType: PropTypes.func,
    description: "Function that executes when user clicks on the icon.",
    required: false,
    default: null,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Input.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Input.propTypes = { ...propTypes };
Input.defaultProps = { ...defaultProps };

Input.displayName = "Input";

export default Input;
