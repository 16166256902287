import React from "react";
import PropTypes from "prop-types";
import Table from "../atoms/table";

function DesignProps({ component, title }) {
  // Convert design system props to tablet format
  const Component = component;
  if (!Component.designSystemProps) {
    return null;
  }

  const createTableRows = (props) => {
    const propsContent = [];
    propsContent.push(["Name", "Description", "Type", "Required", "Default"]);

    Object.entries(props).map(([k, v]) => {
      const row = [
        k,
        v.description,
        Array.isArray(v.type) ? v.type.join(" | ") : v.type,
        JSON.stringify(v.required),
        v.type === "function" && v.default
          ? v.default.toString()
          : JSON.stringify(v.default),
      ];
      propsContent.push(
        v.subProps
          ? {
              row,
              subTable: createTableRows(v.subProps),
              subTableCaption: `${k} sub properties`,
            }
          : row,
      );
      return false;
    });

    return propsContent;
  };

  return (
    <Table
      caption={title}
      content={createTableRows(Component.designSystemProps)}
      headers
    />
  );
}

DesignProps.propTypes = {
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string,
};

DesignProps.defaultProps = {
  // component: null,
  title: "Properties",
};
export default DesignProps;
