// extracted by mini-css-extract-plugin
export var eucalyptus = "#1f3f39";
export var lightEucalyptus = "#b1edd8";
export var buffyOrange = "#dc582a";
export var lightBuffyOrange = "#f7cdb8";
export var buffyPurple = "#9678d3";
export var lightBuffyPurple = "#d5cbf2";
export var subdued = "#666";
export var sand = "#f7f6f1";
export var oatmeal = "#f1ead5";
export var standardWhite = "#fff";
export var standardBlack = "#19191a";
export var purple100 = "#f6f5fa";
export var purple200 = "#d7c9f5";
export var purple300 = "#b79deb";
export var purple = "#7f5ccc";
export var purple500 = "#4836a3";
export var purple600 = "#1d1c66";
export var purpleHoliday = "#28023a";
export var orange100 = "#fff6f2";
export var orange200 = "#fbccb5";
export var orange300 = "#f7a47a";
export var orange = "#e36526";
export var orange500 = "#bd4408";
export var orange600 = "#872d00";
export var orangeHoliday = "#ee5004";
export var buffyYellow = "#fdefb0";
export var navy = "#242a6e";
export var yellow100 = "#f7f6f1";
export var yellow200 = "#fff1cc";
export var yellow300 = "#ffdc67";
export var yellow = "#f7b500";
export var yellow400 = "#e39f00";
export var yellow500 = "#b87a00";
export var yellow600 = "#663d00";
export var green100 = "#fafcf2";
export var green200 = "#d8edaf";
export var green300 = "#b6db6e";
export var green = "#7eaf1d";
export var green500 = "#47660a";
export var green600 = "#2b4000";
export var blue100 = "#f7fbff";
export var blue200 = "#adccf2";
export var blue300 = "#699ee4";
export var blue = "#135bbf";
export var blue500 = "#014392";
export var blue600 = "#00264d";
export var black300 = "#6f6f75";
export var black0 = "#19191a";
export var grey100 = "#f6f5fa";
export var grey200 = "#ececef";
export var grey300 = "#dadadf";
export var grey = "#b0afb8";
export var greyBackground = "#f7f7f7";
export var white0 = "#fff";
export var success = "#009903";
export var error = "#cc0a0a";
export var defaultText = "#1f3f39";
export var defaultButton = "#b1edd8";
export var footer = "#1f3f39";
export var footerButton = "#b1edd8";
export var footerButtonText = "#1f3f39";
export var outline = "#b1edd8";
export var none = "0";
export var micro = ".5";
export var xs = "1";
export var s = "2";
export var m = "3";
export var l = "4";
export var xl = "5";